import { Context as EventContext } from '../../context/EventContext';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import Switch from 'rc-switch';
import { Label } from 'reactstrap';

const HeaderTimezoneSelector = () => {
	const { state, dispatch } = useContext(EventContext);
	const { t } = useTranslation('header');

	const [currentTimezone, setCurrentTimezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);

	const [cookieSelectedSlug, setCookieSelectedSlug] = useState('')
	const [cookieTimezoneSlug, setCookieTimezoneSlug] = useState(state.event && state.event.$organisation)

	useEffect(() => {
		init();
	}, [state.event])

	const init = () => {
		if (!state.event || !state.event.$organisation) return;

		const _selectedSlug = `timezone_${state.event.$slug}_${state.event.$organisation.slug}_selected`
		const _tzSlug = `timezone_${state.event.$slug}_${state.event.$organisation.slug}`

		setCookieSelectedSlug(_selectedSlug);
		setCookieTimezoneSlug(_tzSlug)


		initCurrentTZ(_tzSlug, _selectedSlug);
	}

	const initCurrentTZ = (_cookieTimezoneSlug = cookieTimezoneSlug, _cookieSelectedSlug = cookieSelectedSlug) => {
		let selectedTZ = localStorage.getItem(_cookieTimezoneSlug);
		if (!selectedTZ) {
			if (state.event.$security_data['default_select_tz'] && state.event.$security_data['default_select_tz'] == 'event') {
				localStorage.setItem(_cookieSelectedSlug, 'event');
				localStorage.setItem(_cookieTimezoneSlug, state.event.tz);
				selectedTZ = state.event.tz
			} else {
				selectedTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
			}
		}

		setCurrentTimezone(selectedTZ);
	}

	const changeTimezone = (e) => {
		if (e) {
			// Event
			localStorage.setItem(cookieSelectedSlug, 'event'); // Added for the case where event and local have same timezone
			localStorage.setItem(cookieTimezoneSlug, state.event.tz);
		} else {
			// Local
			localStorage.setItem(cookieSelectedSlug, 'local');
			localStorage.setItem(cookieTimezoneSlug, Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		window.location.reload();
	}

	return (
		<>
			<style>
				{`
					.rc-switch.rc-switch-checked {
						background: `+ state.event.color + `;
						border-color: `+ state.event.color + `;
						color: #fff;
					}
				`}
			</style>
			<div className="text-center timezone-selector">
				<Label htmlFor="switch-timezone">{t('timezone')}: {currentTimezone}</Label>
				<div style={{ whiteSpace: 'nowrap' }}>
					<span>
						<Label htmlFor="switch-timezone" className='mb-0'>
							{t('device-timezone')}
						</Label>
					</span>
					<Switch
						id="switch-timezone"
						className="switch-small mx-2 align-text-top"
						checked={localStorage.getItem(cookieSelectedSlug) == 'event'}
						onClick={(e) => { changeTimezone(e) }}
					/>
					<span>
						<Label htmlFor="switch-timezone" className='mb-0'>
							{t('event-timezone')}
						</Label>
					</span>
				</div>
			</div>
		</>
	)
}

export default HeaderTimezoneSelector;