

export default class EventBlock {
	private id: number;
	private order: number;
	private column_width: number;
	private path: string;
	private settings: Object;
	private icon: string;
	private notification_topic: string;
	private update_topic: string;
	private title: string;
	private visible_in_hybrid: boolean;
	private visible_for: Array<string>;
	private dynamic_block: any;
	private models: Array<any>;

	constructor(
		id: number = 0,
		order: number = 1,
		column_width: number = 12,
		path: string = "",
		settings: Object = {},
		icon: string = "",
		notification_topic: string = "",
		title: string = "",
		update_topic: string = "",
		visible_in_hybrid: boolean = true,
		visible_for: Array<string> = [],
		dynamic_block: any = null,
		models: Array<any> = []
	) {
		this.id = id;
		this.order = order;
		this.column_width = column_width;
		this.path = path;
		this.settings = settings;
		this.icon = icon;
		this.notification_topic = notification_topic;
		this.update_topic = update_topic;
		this.title = title;
		this.visible_for = visible_for;
		this.visible_in_hybrid = visible_in_hybrid;
		this.dynamic_block = dynamic_block
		this.models = models;
	}

	public static fromJSON(json: any): EventBlock {
		var block = new EventBlock();
		Object.assign(block, json);

		if (json.models) {
			const models = [];
			for (let m of json.models) {
				models.push(m);
			}
			block.$models = models;
		}

		return block;
	}


	/**
	 * GETTERS
	 */
	public get $id(): number {
		return this.id;
	}
	public get $order(): number {
		return this.order;
	}

	public get $column_width(): number {
		return this.column_width;
	}

	public get $path(): string {
		if ((!this.path || this.path == "") && this.dynamic_block) {
			return this.dynamic_block.settings_react_path
		}
		return this.path;
	}

	public get $settings(): Object {
		return this.settings;
	}

	public get $icon(): string {
		return this.icon;
	}
	public get $notification_topic(): string {
		return this.notification_topic;
	}
	public get $title(): string {
		if (!this.title) {
			return this.$dynamic_block.name
		}
		return this.title;
	}
	public get $update_topic(): string {
		return this.update_topic;
	}
	public get $visible_in_hybrid(): boolean {
		return this.visible_in_hybrid;
	}
	public get $dynamic_block(): any {
		return this.dynamic_block;
	}
	public get $models(): Array<any> {
		return this.models;
	}
	public get $visible_for(): Array<string> {
		return this.visible_for;
	}

	/**
	 * SETTERS
	 */
	public set $id(value: number) {
		this.id = value;
	}
	public set $order(value: number) {
		this.order = value;
	}

	public set $column_width(value: number) {
		this.column_width = value;
	}

	public set $path(value: string) {
		this.path = value;
	}

	public set $settings(value: Object) {
		this.settings = value;
	}

	public set $icon(value: string) {
		this.icon = value;
	}
	public set $notification_topic(value: string) {
		this.notification_topic = value;
	}
	public set $title(value: string) {
		this.title = value;
	}
	public set $update_topic(value: string) {
		this.update_topic = value;
	}
	public set $visible_in_hybrid(value: boolean) {
		this.visible_in_hybrid = value;
	}
	public set $dynamic_block(value: any) {
		this.dynamic_block = value;
	}
	public set $models(value: Array<any>) {
		this.models = value;
	}



};