import React, { useState, useContext, useEffect } from 'react'
import { Context as EventContext } from '../../context/EventContext'
import { Context as LanguageContext } from '../../context/LanguageContext'
import LayoutRenderer from '../LayoutRenderer'
import EventBlock from '../../Models/EventBlock'
import { useTranslation } from "react-i18next";

const FooterZone = () => {
	const { state, dispatch } = useContext(EventContext);
	const { languageState, languageDispatch } = useContext(LanguageContext);

	const [layout, setLayout] = useState<any>();
	const [blocks, setBlocks] = useState<EventBlock[]>([])

	const { t, i18n } = useTranslation('header');

	const [desktopTemplate, setDesktopTemplate] = useState("default");
	const [mobileTemplate, setMobileTemplate] = useState("default");

	const [showFooter, setShowFooter] = useState<boolean>(false)

	const [backgroundColor, setBackgroundColor] = useState('#ffffff');

	const init = async () => {
		if (!state.event.$id || !state.event.$navigation.footer) return;

		let _showFooter = false;
		for (let zone of state.event.$zones) {
			if (zone.$name == 'Footer zone') {
				if (zone.$zone_settings) {
					for (let setting of zone.$zone_settings) {
						if (setting.slug == 'show_zone' && (setting.value === 'true' || setting.value)) {
							setShowFooter(true);
							_showFooter = true;
						}
						if (setting.slug == 'background_color') {
							setBackgroundColor(setting.value);
						}

						// if (setting.slug == 'desktop_template' && setting.value) {
						// 	setDesktopTemplate(setting.value);
						// }
						// if (setting.slug == 'mobile_template' && setting.value) {
						// 	setMobileTemplate(setting.value);
						// }
					}
				}
			}
		}

		if (!_showFooter) return;

		try {
			const layout = JSON.parse(state.event.$navigation.footer.settings);
			setLayout(layout);

			const _blocks: Array<EventBlock> = [];
			if (state.event.$navigation.footer) {
				for (let b of state.event.$navigation.footer.blocks) {
					_blocks.push(EventBlock.fromJSON(b));
				}
			}
			setBlocks(_blocks);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		init();
	}, [state.event, languageState.currentLanguage])

	return (
		<>
			{showFooter ?
				<div className='footer' style={{ backgroundColor: backgroundColor }}>
					<LayoutRenderer
						layout={layout}
						blocks={blocks}
					/>
				</div>
				: ""}
		</>
	);
}

export default FooterZone;