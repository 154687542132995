import React, { useEffect, FC, useContext, useMemo } from 'react';
import { Context as EventContext } from '../../context/EventContext';
import { Context as UserContext } from '../../context/UserContext';
import { withSocket } from '../../HOC/Socket/withSocket';
import UserService from '../../Services/UserService';
import AuthService from '../../Services/AuthService';
import AnalyticsService from '../../Services/AnalyticsService';

const UserIsAlive: FC = (props) => {
	const { state, eventDispatch } = useContext(EventContext);
	const { userState, userDispatch } = useContext(UserContext);


	useMemo(() => {
		if(!userState.user.$id || !state.event.$id) return;
		
		const userId = userState.user.$id;
		props.subscribe(`user/${userId}/areYouLoggedIn`, (msg) => {
			// User is logged in elsewhere
			console.log('Someone wants to login with this account');
			UserService.iAmLoggedIn(state.event.$id);
		}, true);

		props.subscribe(`user/${userId}/logout`, (msg) => {
			// User has authenticated through 2FA
			console.log('You get logged out');
			AnalyticsService.post(
				{ event: '2FA LOGGED OUT' },
				window.location.pathname,
				state.event.id,
				userState.user.id,
				'auth'
			);
	
			AuthService.logout();
			window.location.reload();
		}, true);
	}, [state.event, userState.user])
	
	function init() {
		
	}

	useEffect(() => {
		//init();
	}, [state, userState])

	return <></>
}

export default withSocket(UserIsAlive);