
import LanguagePicker from '../../components/LanguagePicker'
import { Context as EventContext } from '../../context/EventContext';
import { Context as LanguageContext } from '../../context/LanguageContext';
import React, { useState, useEffect, useContext } from 'react';
import i18n from "i18next";
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";
import Switch from 'rc-switch';
import { Label } from 'reactstrap';

interface props {
	eventID: number;
	hideLabels?: boolean;
	onChange?: Function;
	direction?: string;
}

const HeaderHybridSelector = (props) => {
	const navigate = useNavigate();
	const { state, dispatch } = useContext(EventContext);
	const [isHybrid, setIsHybrid] = useState(false);
	const { t, i18n } = useTranslation('header');

	const checkParameter = () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		let hybridEnabled = urlParams.get('hybrid');

		if (hybridEnabled == undefined) {
			const localItem = localStorage.getItem('is_hybrid');
			if (localItem != undefined) {
				hybridEnabled = localItem;
			} else {
				hybridEnabled = 'off';
			}
		}

		localStorage.setItem('is_hybrid', hybridEnabled);

		setIsHybrid(hybridEnabled == 'on')
	}

	useEffect(() => {
		checkParameter();
	}, []);

	return (
		<>
			<style>
				{
					`.rc-switch.rc-switch-checked {
					background: `+ state.event.color + `;
					border-color: `+ state.event.color + `;
						color: #fff;
				}`
				}
			</style>
			<span className="mr-3">
				<Label htmlFor='hybrid-switch' className='mb-0'>
					{t('hybrid')}
				</Label>
			</span>
			<Switch
				id='hybrid-switch'
				checked={isHybrid}
				className="switch-small"
				onChange={(e) => {
					let state = "off";
					if (e) {
						state = "on";
					}
					const queryString = window.location.search;
					const urlParams = new URLSearchParams(queryString);
					let paramStr = "?"

					urlParams.forEach((val, key) => {
						if (key != 'hybrid') {
							paramStr += key + "=" + val + "&"
						}
					})
					paramStr += "hybrid=" + state
					navigate(window.location.pathname + paramStr);

				}}
			/>
		</>
	);
};

export default HeaderHybridSelector;
