import Session from "./Session";
import EventBlock from "./EventBlock";
import EventRoom from "./EventRoom";
import Exhibition from "./Exhibition";
import Service from "./Service";
import Speaker from "./Speaker";

export default class PaymentPlan {
	private id: number | null = null;
	private name: string | null = null;
	private description: string | null = null;
	private price: number | null = null;
	private eventblocks: Array<EventBlock> | null = [];
	private speakers: Array<Speaker> | null = [];
	private services: Array<Service> | null = [];
	private exhibitions: Array<Exhibition> | null = [];
	private rooms: Array<EventRoom> | null = [];
	private sessions: Array<Session> | null = [];
	private limit_exceeded: boolean | null = false;

	constructor(
		id: number | null = null,
		name: string | null = null,
		price: number | null = null,
		eventblocks: Array<EventBlock> | null = null,
		speakers: Array<Speaker> | null = null,
		services: Array<Service> | null = null,
		exhibitions: Array<Exhibition> | null = null,
		rooms: Array<EventRoom> | null = null,
		sessions: Array<Session> | null = null,
		limit_exceeded: boolean | null = null
	) {
		this.id = id;
		this.name = name;
		this.price = price;
		this.eventblocks = eventblocks;
		this.speakers = speakers;
		this.services = services;
		this.exhibitions = exhibitions;
		this.rooms = rooms;
		this.sessions = sessions;
		this.limit_exceeded = limit_exceeded;
	}

	public static fromJSON(json: any): PaymentPlan {
		const plan = new PaymentPlan();
		Object.assign(plan, json);

		var _eventBlocks = new Array<EventBlock>();
		if (json.eventblocks) {
			json.eventblocks.forEach((block: Object) => {
				_eventBlocks.push(EventBlock.fromJSON(block));
			})
		}
		plan.$eventblocks = _eventBlocks;

		var _speakers = new Array<Speaker>();
		if (json.speakers) {
			json.speakers.forEach((speaker: Object) => {
				_speakers.push(Speaker.fromJSON(speaker));
			});
		}
		plan.$speakers = _speakers;

		var _services = new Array<Service>();
		if (json.services) {
			json.services.forEach((service: Object) => {
				_services.push(Service.fromJSON(service));
			});
		}
		plan.$services = _services;

		var _exhibitions = new Array<Exhibition>();
		if (json.exhibitions) {
			json.exhibitions.forEach((exhibition: Object) => {
				_exhibitions.push(Exhibition.fromJSON(exhibition));
			});
		}
		plan.$exhibitions = _exhibitions;

		var _rooms = new Array<EventRoom>();
		if (json.rooms) {
			json.rooms.forEach((room: Object) => {
				_rooms.push(EventRoom.fromJSON(room));
			});
		}
		plan.$rooms = _rooms;

		var _sessions = new Array<Session>();
		if (json.sessions) {
			json.sessions.forEach((session: Object) => {
				_sessions.push(Session.fromJSON(session));
			});
		}
		plan.$sessions = _sessions;


		return plan;
	}

	public get $id(): number | null { return this.id; }
	public set $id(value: number | null) { this.id = value; }

	public get $name(): string | null { return this.name; }
	public set $name(value: string | null) { this.name = value; }

	public get $description(): string | null { return this.description; }
	public set $description(value: string | null) { this.description = value; }

	public get $price(): number | null { return this.price; }
	public set $price(value: number | null) { this.price = value; }

	public get $eventblocks(): Array<EventBlock> | null { return this.eventblocks; }
	public set $eventblocks(value: Array<EventBlock> | null) { this.eventblocks = value; }

	public get $speakers(): Array<Speaker> | null { return this.speakers; }
	public set $speakers(value: Array<Speaker> | null) { this.speakers = value; }

	public get $services(): Array<Service> | null { return this.services; }
	public set $services(value: Array<Service> | null) { this.services = value; }

	public get $exhibitions(): Array<Exhibition> | null { return this.exhibitions; }
	public set $exhibitions(value: Array<Exhibition> | null) { this.exhibitions = value; }

	public get $rooms(): Array<EventRoom> | null { return this.rooms; }
	public set $rooms(value: Array<EventRoom> | null) { this.rooms = value; }

	public get $sessions(): Array<Session> | null { return this.sessions; }
	public set $sessions(value: Array<Session> | null) { this.sessions = value; }

	public get $limit_exceeded(): boolean | null { return this.limit_exceeded; }
	public set $limit_exceeded(value: boolean | null) { this.limit_exceeded = value; }
}
