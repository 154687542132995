import React, { FC } from 'react';
import illustration1 from '../../assets/images/error.jpg'
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PageError: FC = () => {

	return (
		<>
			<div className="app-wrapper bg-white">
				<div className="app-main">
					<div className="app-content p-0">
						<div className="app-inner-content-layout--main">
							<div className="flex-grow-1 w-100 d-flex align-items-center">
								<div className="bg-composed-wrapper--content">
									<div className="hero-wrapper bg-composed-wrapper min-vh-100">
										<div className="flex-grow-1 w-100 d-flex align-items-center">
											<Col lg="6" md="9" className="px-4 px-lg-0 mx-auto text-center text-black">
												<img src={illustration1} className="w-50 mx-auto d-block my-5 img-fluid" alt="..." />

												<h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
													Whoops, something went wrong...
												</h3>
												<p>
													It's on us, we notified our team about the issue! Please return to the previous page
												</p>
												<Row className="d-flex justify-content-center mt-5">
													<Button
														color="neutral-primary"
														onClick={(e) => {
															e.preventDefault();
															window.history.go(-1);
														}}
													>
														<FontAwesomeIcon icon="fa-solid fa-chevron-left" className="mr-2"/>
														Return
													</Button>
												</Row>
											</Col>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}


export default PageError;