import axios from "axios"
import AuthService from "./AuthService"

export const VideoChatRoomService = {
    createPrivateRoom: async (eventId: number, receipientIds: Array<number>) => {
        const path = 'networking-tools/' + eventId + '/private';
        const data = {
            'event_id': eventId,
            'receivers': receipientIds
        }

        const resp = await AuthService.post(path, data);
        return resp.data.data;
    },
    schedulePrivateCall: async (eventId: number, receipientIds: Array<number>, start: string, description: string) => {
        const path = 'networking-tools/' + eventId + '/private/schedule';
        const date = new Date(start);
        const data = {
            'event_id': eventId,
            'invited': receipientIds,
            'start': date.toISOString(),
            'description': description
        }

        const resp = await AuthService.post(path, data);
        return resp;
    },
    getScheduledCalls: async (eventId) => {
        const path = 'networking-tools/' + eventId + '/private/schedule';

        const resp = await AuthService.get(path);
        return resp;
    },
    acceptCall: async (eventId: number, roomId: string) => {
        const path = 'networking-tools/' + eventId + '/' + roomId + '/accept';
        const resp = await AuthService.get(path);

        return resp;
    },
    endCall: async (eventId: number, roomId: string) => {
        const path = 'networking-tools/' + eventId + '/' + roomId + '/decline';
        const resp = await AuthService.get(path);

        return resp;
    },
    acceptInvite: async (eventId: number, inviteId: string) => {
        const path = 'networking-tools/' + eventId + '/private/schedule/' + inviteId;
        const data = {
            'status': 'accepted'
        }
        const resp = await AuthService.put(path, data);

        return resp.data.data;
    },
    declineInvite: async (eventId: number, inviteId: string) => {
        const path = 'networking-tools/' + eventId + '/private/schedule/' + inviteId;
        const data = {
            'status': 'declined'
        }
        const resp = await AuthService.put(path, data);

        return resp.data.data;
    },
    deleteInvite: async (eventId: number, inviteId: string) => {
        const path = 'networking-tools/' + eventId + '/private/schedule/' + inviteId;
        const resp = await AuthService.delete(path);

        return resp;

    },
    changeInvite: async (eventId: number, inviteId: number, start: string, description: string) => {
        const path = 'networking-tools/' + eventId + '/private/schedule/' + inviteId;
        const date = new Date(start);
        const data = {
            'start': date.toISOString(),
            'description': description
        }
        const resp = await AuthService.put(path, data);

        return resp.data.data;
    },
    updateVideoChatRoom: async function (eventId: number, roomId: number, participantIds: Array<number>) {
        const path = '/networking-tools/' + eventId + "/private/" + roomId;
        const data = {
            'receivers': participantIds,
        }
        const resp = await AuthService.put(path, data);
        return resp.data.data;
    },
    getBreakoutParticipants: async function (eventId: number, roomId: number) {
        const path = '/networking-tools/' + eventId + '/' + roomId + '/participants';
        const resp = await AuthService.get(path);
        return resp;
    },
    kickParticipant: async function (eventId: number, roomId: number, userId: number) {
        const path = '/networking-tools/' + eventId + '/' + roomId + '/kick'
        const data = {
            'kicked_user': userId,
        }

        const resp = await AuthService.post(path, data);
        return resp;
    },
    banParticipant: async function (eventId: number, roomId: number, userId: number) {
        const path = '/networking-tools/' + eventId + '/' + roomId + '/ban'
        const data = {
            'banned_user': userId,
        }

        const resp = await AuthService.post(path, data);
        return resp;
    },
    unbanParticipant: async function (eventId: number, roomId: number, userId: number) {
        const path = '/networking-tools/' + eventId + '/' + roomId + '/unban'
        const data = {
            'banned_user': userId,
        }

        const resp = await AuthService.post(path, data);
        return resp;
    }
}