import axios from "axios"
import AuthService from "./AuthService"
import Event from "../Models/Event"

const EventService = {
	/**
	 * Do a get request to fetch the event for the slug.
	 * @param {string} path The path for the request tot the API
	 * @throws error if the http response was incorrect.
	 */
	getWithSlug: async (
		url: String,
		organisationSlug: String,
		eventSlug: String,
		languageSlug: String
	) => {
		const encoded_url = btoa(url)
		const response = await AuthService.get("event/slug", {
			url: encoded_url,
			...(organisationSlug && { organisationSlug: organisationSlug }),
			...(eventSlug && { eventSlug: eventSlug }),
			...(languageSlug && { languageSlug: languageSlug })
		})
		return Event.fromJSON(response)
	},

	/**
	 * Do a get request to fetch the event for the slug.
	 * @param {string} path The path for the request tot the API
	 * @throws error if the http response was incorrect.
	 */
	getSecuritySlug: async (
		url: String,
		organisationSlug: String,
		eventSlug: String,
		languageSlug: String | null = null
	) => {
		const encoded_url = btoa(url)
		const userCookie = localStorage.getItem('user_cookie');
		const response = await axios.get(
			process.env.REACT_APP_API_URL + "event/slug/security",
			{
				params: {
					url: encoded_url,
					...(organisationSlug && { organisationSlug: organisationSlug }),
					...(eventSlug && { eventSlug: eventSlug }),
					...(languageSlug && { languageSlug: languageSlug }),
					...(userCookie && { userCookie: userCookie })
				},
			}
		)
		return response.data.data
	},
	validateEmail: async (eventId: number, email: String) => {
		const response = await axios.get(
			process.env.REACT_APP_API_URL + "event/" + eventId + "/security/validate_mail",
			{
				params: {
					...(email && { email: email })
				},
			}
		).catch(function (error) {

			throw new Error(error.response.data.code);
		})
		return response.data.data
	},
	validateEventCode: async (eventId: number, eventCode: string) => {
		const response = await axios.get(
			process.env.REACT_APP_API_URL + "event/" + eventId + "/security/validate_event_code",
			{
				params: {
					...(eventCode && { eventCode: eventCode })
				},
			}
		)
		return response.data.data
	},
	validateInviteCode: async (eventId: number, inviteCode: string) => {
		const response = await axios.get(
			process.env.REACT_APP_API_URL + "event/" + eventId + "/security/validate_invite_code",
			{
				params: {
					...(inviteCode && { inviteCode: inviteCode })
				},
			}
		)
		return response.data.data
	},
	getMissingUserFields: async (eventId: number) => {
		const response = await axios.get(
			process.env.REACT_APP_API_URL + "event/" + eventId + "/security"
		);
		return response.data.data;
	},
	getBasepath: async (url, organisationSlug, eventSlug) => {
		const encoded_url = btoa(url)
		const resp = await axios.get(process.env.REACT_APP_API_URL + `event/decode-basepath`, {
			params: {
				url: encoded_url,
				...(organisationSlug && { organisationSlug: organisationSlug }),
				...(eventSlug && { eventSlug: eventSlug }),
			}
		});

		return resp.data.data;
	},
	getUserInfo: async (eventId: number) => {
		const response = await AuthService.get(
			"event/" + eventId + "/user-info"

		);
		return response;
	}
}

export default EventService
