import React from 'react';
import { NotificationsContext } from './Notifications';

export function withNotifications(WrappedComponent: any) {
	return class extends React.Component {
		static contextType = NotificationsContext

		constructor(props: any) {
			super(props)
		}

		render() {
			return (
				<WrappedComponent
				{...this.props}
				/>
			)
		}

	}
}