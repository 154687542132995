import React, { createContext, useState, useEffect, FC, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _uniqueId from 'lodash/uniqueId';
import { connect, MqttClient } from "mqtt/dist/mqtt"

interface SocketProps {
	children: any
}

export const SocketContext = createContext({});
export const defaultQOS = 1;
export const defaultMQTTBroker = process.env.REACT_APP_MQTT_BROKER

const getMqttOptions = () => {
	const clientId = "mqtt_" + Math.random().toString(16).substr(2, 8)
	const options = {
		clientId: clientId,
		keepalive: 60
	};
	return options;
}

const Socket: FC<SocketProps> = ({ children }) => {

	const [options, setOptions] = useState(getMqttOptions)
	const [client, setClient] = useState<MqttClient>(null);
	const [connectStatus, setConnectStatus] = useState<string | null>(null);
	const [subListeners, setSubListeners] = useState<any>([]);

	useMemo(() => {
		if (client) {
			client.on('connect', () => {
				setConnectStatus('Connected');
			});
			client.on('error', (err) => {
				console.error('Connection error: ', err);
				client.end();
			});
			client.on('reconnect', () => {
				setConnectStatus('Reconnecting');
			});
			client.on('message', (topic, message) => {
				const payload = { topic, message: message.toString() };
				onMessage(topic, payload);
			});
			client.on('close', () => {
			})
		}
	}, [client])

	useEffect(() => {
		mqttConnect(defaultMQTTBroker);
	}, []);

	const onMessage = (topic, message) => {
		var mqttWildcard = require('mqtt-wildcard')
		for (let subListener of subListeners) {
			const topicMatch = mqttWildcard(topic, subListener.topic)
			if (topicMatch != null) {
				subListener.listener(message);
			}
		}
	}
	const mqttConnect = (host) => {
		if (!client) {
			setConnectStatus('Connecting');
			setClient(connect(host));
		}
	}
	const mqttSub = (topic, eventListener, forceUnique, qos) => {
		if (client) {
			client.subscribe(topic, { qos }, (error) => {
				if (error) {

					return
				} else {
					addListener(topic, eventListener, forceUnique)
				}
			});
		}
	};
	const mqttPublish = (topic, payload, options = {}) => {
		if (client) {
			client.publish(topic, payload, options, error => {
				if (error) {

				}
			});
		}
	}

	const getClientId = () => {
		return options.clientId;
	}
	const addListener = (topic, eventListener, forceUnique) => {
		if (forceUnique) {
			let index = 0;
			for (let subListener of subListeners) {
				if (subListener.topic == topic && subListener.listener.toString() == eventListener.toString()) {
					subListeners.splice(index, 1);
				}
				index++;
			}
		}

		subListeners.push({
			topic: topic,
			listener: eventListener
		});
		setSubListeners(subListeners);
	}





	return (
		<SocketContext.Provider value={{ subscribeTo: mqttSub, publishTo: mqttPublish, mqttConnect: mqttConnect, getClientId: getClientId }}>
			{children}
		</SocketContext.Provider>
	)
}
export default Socket;