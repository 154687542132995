
export default class Service {
    private id: number | null
    private name: String
    private icon: String | null
    private description: String | null
    private active: boolean
    
  
    constructor(
      id: number | null = 0,
      name: String = "",
      icon: String = "",
      description: String = "",
      active: boolean = false
    ) {
      this.id = id
      this.name = name
      this.description = description
      this.icon = icon
      this.active = active
    }
  
    /**
     * Create a Service object from json
     * @param json The json representation of a service
     * @return {Service} A service object based on the information in the provided json
     */
    public static fromJSON(json: any): Service {
      var service: Service = new Service()
      Object.assign(service, json)
  
  
      return service
    }
  
    public get $name(): String {
      return this.name
    }
  
    public get $icon(): String | null {
      return this.icon
    }
  
    public get $description(): String | null {
      return this.description
    }
  
    public get $id(): number | null {
      return this.id
    }
  
    public get $active(): boolean {
      return this.active
    }
  
    public set $active(value:boolean) {
      this.active = value;
    }
  
  }
  