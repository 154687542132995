import AuthService from './AuthService';
import EventRoom from '../Models/EventRoom';
import Session from '../Models/Session';

const SessionService = {
    getAll: async function(eventId: number, params = {}) : Promise<Array<Session>> {
        const sessions = await AuthService.get(`event/${eventId}/sessions`, params);
        const result = [];
        for(let e of sessions) {
            const session = Session.fromJSON(e);
            result.push(session);
        }
        return result;
    },
    get: async function(eventId: number, sessionId: number) : Promise<Session> {
        const session = await AuthService.get(`event/${eventId}/sessions/${sessionId}`);
        return Session.fromJSON(session);
    },
    getBySlug: async function(eventId: number, slug: string, languageSlug) : Promise<Session> {
        const response = await AuthService.get('event/' + eventId + '/sessions/slug/' + slug + "?lang=" + languageSlug);
        const session = Session.fromJSON(response);
        return session;
    },
    register: async function(eventId: number, sessionId: number) {
        const resp = await AuthService.post('event/' + eventId + '/sessions/' + sessionId + "/register", {});
        return resp;
    },
    unregister: async function(eventId: number, sessionId: number) {
        const resp = await AuthService.post('event/' + eventId + '/sessions/' + sessionId + "/unregister", {});
        return resp;
    },
    getRegistrations: async function(eventId: number, langSlug: string | null= null) : Promise<Array<Session>> {
        const resp = await AuthService.get('event/' + eventId + '/sessions/registrations?lang=' + langSlug);
        const result: Array<Session> = [];

        for(let r of resp) {
            const session = Session.fromJSON(r);
            result.push(session);
        }

        return result
    }

}

export default SessionService;