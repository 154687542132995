
export default class LanguageUtils {
	/**
	 * Store the language for an event in local storage
	 * @param eventId Id of the event
	 * @param languageSlug 
	 */
	static storeLanguage(eventId: number, languageSlug: string): void {
		localStorage.setItem(this._languageKey(eventId), languageSlug);
	}

	/**
	 * Store the chosen language for an event
	 * @param eventSlug Slug of the event in which the language is chosen
	 * @param organisationSlug Organisation of the event
	 * @param languageSlug Slug of the chosen language
	 */
	static storeLanguageBySlug(eventSlug: string, organisationSlug: string, languageSlug: string): void {
		localStorage.setItem(`language-event-${eventSlug}-${organisationSlug}`, languageSlug);
	}

	/**
	 * Get the language for an event the user has chosen/stored
	 * @param eventId Id of the event
	 * @returns language slug
	 */
	static getLanguageBySlug(eventSlug: string, organisationSlug: string): string | null {
		return localStorage.getItem(`language-event-${eventSlug}-${organisationSlug}`);
	}
	/**
	 * Get the language for an event by slug from local storage
	 * @param eventId Id of the evnet
	 * @returns language slug
	 */
	static getLanguage(eventId: number): string | null {
		return localStorage.getItem(this._languageKey(eventId));
	}
	
	/**
	 * Create the local storage key of the language for an event
	 * @param eventId Id of the event
	 * @returns language slug
	 */
	static _languageKey(eventId: number): string {
		return 'language-event-' + eventId;
	}
}
