import PaymentPlan from "./PaymentPlan"

export default class User {
	private id: Number | null;
	private first_name: String | null;
	private last_name: String | null;
	private email: String | null;
	private avatar: string | null;

	private isWatchingAs: string | null;
	private paymentPlans: Array<PaymentPlan> | null;

	private socials: any | null;

	private address: Object;

	private receive_emails: boolean;
	private receive_notifications: boolean;


	constructor(
		id: Number | null = null,
		first_name: String | null = null,
		last_name: String | null = null,
		email: String | null = null,
		avatar: string | null = null,
		paymentPlans: Array<PaymentPlan> | null = null,
		socials: any | null = null,
		address: Object = {},
		receive_emails: boolean = true,
		receive_notifications: boolean = true,
	) {
		this.id = id;
		this.first_name = first_name;
		this.last_name = last_name;
		this.email = email;
		this.avatar = avatar;
		this.isWatchingAs = null;
		this.paymentPlans = paymentPlans;
		this.socials = socials;
		this.address = address;
		this.receive_emails = receive_emails;
		this.receive_notifications = receive_notifications
	}


	public static fromJSON(json: any): User {
		var user: User = new User();
		Object.assign(user, json);

		const _plans = [];
		if (json.payment_plans) {
			for (let p of json.payment_plans) {
				_plans.push(PaymentPlan.fromJSON(p));
			}
		}
		user.$paymentPlans = _plans;

		if (json.socials)
			user.socials = json.socials;

		if (json.address)
			user.address = json.address;
		else
			user.address = {};

		return user;
	}


	/**
	 * Getter $id
	 * @return {Number }
	 */
	public get $id(): Number | null {
		return this.id;
	}

	/**
	 * Getter $first_name
	 * @return {String}
	 */
	public get $first_name(): String | null {
		return this.first_name;
	}

	/**
	 * Getter $last_name
	 * @return {String}
	 */
	public get $last_name(): String | null {
		return this.last_name;
	}

	/**
	 * Getter $email
	 * @return {String}
	 */
	public get $email(): String | null {
		return this.email;
	}

	public get $avatar(): string | null {
		return this.avatar;
	}
	public get $isWatchingAs(): string | null {
		return this.isWatchingAs;
	}
	public get $paymentPlans(): Array<PaymentPlan> | null {
		return this.paymentPlans;
	}
	public get $socials(): any | null {
		return this.socials;
	}
	public get $address(): Object {
		return this.address;
	}
	public get $receive_emails(): boolean {
		return this.receive_emails;
	}
	public get $receive_notifications(): boolean {
		return this.receive_notifications;
	}

	public getSocialPlatform(platform: string): any | null {
		for (const social of this.socials) {
			if (social.platform == platform)
				return social;
		}
		return null;
	}


	/**
	 * Setter $id
	 * @param {Number } value
	 */
	public set $id(value: Number | null) {
		this.id = value;
	}

	/**
	 * Setter $first_name
	 * @param {String} value
	 */
	public set $first_name(value: String | null) {
		this.first_name = value;
	}

	/**
	 * Setter $last_name
	 * @param {String} value
	 */
	public set $last_name(value: String | null) {
		this.last_name = value;
	}

	/**
	 * Setter $email
	 * @param {String} value
	 */
	public set $email(value: String | null) {
		this.email = value;
	}

	public set $avatar(value: string | null) {
		this.avatar = value;
	}
	public set $isWatchingAs(value: string | null) {
		this.isWatchingAs = value;
	}

	public set $paymentPlans(value: Array<PaymentPlan> | null) {
		this.paymentPlans = value;
	}

	public set $socials(value: any | null) {
		this.socials = value;
	}

	public set $address(value: Object) {
		this.address = value;
	}

	public set $receive_emails(value: boolean) {
		this.$receive_emails = value;
	}

	public set $receive_notifications(value: boolean) {
		this.receive_notifications = value;
	}
}