import Language from '../Models/Language';
import LanguageService from '../Services/LanguageService';
import { useTranslation, initReactI18next } from "react-i18next";
import { AsyncActionHandlers } from 'use-reducer-async';
import { Reducer } from 'react';
import i18n from 'i18next';
import LanguageUtils from '../utils/LanguageUtils';

type AsyncAction = {
	type: 'SET_TRANSLATION_DATA';
	data: any;
	callback: Function;
	errorCallback;
	eventId: number;
};

const createTranslator = async (url) => {
	const resources = await LanguageService.downloadTranslationFromAws(url);
	for (let lang in resources) {
		for (let namespace in resources[lang]) {
			if (lang == 'GB') {
				i18n.addResourceBundle('EN', namespace, resources[lang][namespace]);
			} else {
				i18n.addResourceBundle(lang, namespace, resources[lang][namespace]);
			}
		}
	}
	await i18n.reloadResources();
	// i18n.on('missingKey', function  (lng, ns, key, fallbackValue, updateMissing, options)  {
	//     if(ns == 'form') {
	//         return;
	//     }
	//     
	//     LanguageService.createTranslationKey(ns, key);
	//   })
	// return resources;
}

const parseLanguageArray = (jsonArray: any) => {
	const result = [];
	for (let l of jsonArray) {
		const lang = Language.fromJSON(l);
		result.push(lang);
	}
	return result;
}
const getDefaultFromArray = (languageArr: Array<Language>, eventId: number) => {
	let storageSlug = LanguageUtils.getLanguage(eventId);
	if (storageSlug == 'GB') storageSlug = 'EN';

	if (storageSlug) {
		for (let l of languageArr) {
			if (l.$slug == storageSlug) {
				return l;
			}
		}
	}
	for (let l of languageArr) {
		if (l.$is_default) {
			return l;
		}
	}
	return null;
}

type State = {
	currentLanguage: Language;
	possibleLanguages: Array<Language>;
	translations: any;
}

type Action =
	| { type: 'START_FETCH' }
	| { type: 'END_FETCH'; currentLanguage: Language, possibleLanguages: Array<Language>, translations: any }
	| { type: 'ERROR_FETCH'; code: String };



const asyncActionHandlers: AsyncActionHandlers<Reducer<State, Action>, AsyncAction> = {
	SET_TRANSLATION_DATA: ({ dispatch }) => async (action) => {
		dispatch({ type: 'START_FETCH' });
		const languages = parseLanguageArray(action.data.languages);
		const currentLanguage = getDefaultFromArray(languages, action.eventId);
		i18n.changeLanguage(currentLanguage.$slug);
		const translation = await createTranslator(action.data.translation.translation_url)
		dispatch({
			type: 'END_FETCH',
			currentLanguage: currentLanguage,
			possibleLanguages: languages,
			translations: translation
		})
		if (action.callback) action.callback(currentLanguage)

	}
}

const LanguageReducer = (state, action) => {
	switch (action.type) {
		case 'START_FETCH':
			return {
				...state,
				loading: true,
			};
		case 'END_FETCH':
			return {
				...state,
				loading: false,
				currentLanguage: action.currentLanguage,
				possibleLanguages: action.possibleLanguages,
				translations: action.translations,
				error: false,
			}
		case 'ERROR_FETCH':
			return {
				...state,
				currentLanguage: null,
				possibleLanguages: null,
				translations: null,
				loading: false,
				code: action.code,
				error: true,
			}
		case 'SET_CURRENT_LANGUAGE':
			return {
				...state,
				currentLanguage: action.currentLanguage,
			}
		default:
			return { ...state };
	}
}

export default LanguageReducer;
export { asyncActionHandlers }