import AuthService from './AuthService'

const LayoutService = {
	getModeratorPanels: async function (eventId): Promise<any> {
		try {
			const resp = await AuthService.get('event/' + eventId + '/layout/moderator');
			return resp;
		} catch (err) {
			return [];
		}

	},
	getFrontendPanels: async function (eventId, languageSlug = null): Promise<any> {
		try {
			let url = `event/${eventId}/layout/frontend`;
			if (languageSlug) {
				url += "?lang=" + languageSlug;
			}
			const resp = await AuthService.get(url);

			return resp;
		} catch (err) {
			return [];
		}
	},

}
export default LayoutService;