import { Context as LanguageContext } from '../../context/LanguageContext';
import React, { useState, useEffect, useContext } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import _uniqueId from 'lodash/uniqueId';
import LanguageUtils from '../../utils/LanguageUtils';
import ColorUtils from '../../utils/ColorUtils';

interface Props {
	eventId: number;
	hideLabels?: boolean;
	onChange?: Function;
	direction?: string;
	relative?: boolean;
	eventSlug: string;
	organisationSlug: string;
	eventColor: string;
}

const HeaderLanguageSelector = (props: Props) => {
	const { languageState, languageDispatch } = useContext(LanguageContext)
	const [isLoading, setIsLoading] = useState(true);
	const [url, setUrl] = useState(window.location.origin);
	const [showLabels, setShowLabels] = useState(true);
	const [direction, setDirection] = useState('DOWN');
	const [selectorId, setSelectorId] = useState(_uniqueId("language-selector-"))

	const changeLanguage = async (e) => {
		if (!e) return;

		if (e != LanguageUtils.getLanguage(props.eventId)) {
			LanguageUtils.storeLanguage(props.eventId, e);
			LanguageUtils.storeLanguageBySlug(props.eventSlug, props.organisationSlug, e);

			let url = new URL(window.location.href);
			let params = new URLSearchParams(url.search);
			params.delete('lang');

			let newUrl = url.origin + url.pathname;
			window.location.href = newUrl;
		}
	}

	const handleLanguageParameter = () => {
		const currentSlug: string = languageState.currentLanguage.slug;
		const storageSlug = LanguageUtils.getLanguage(props.eventId);

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const parameterSlug = urlParams.get('lang');

		if (storageSlug != parameterSlug) {
			changeLanguage(parameterSlug);
		}
	}

	const countries = () => {
		const countries: string[] = languageState.possibleLanguages.map((lang) => { return lang.$flag_slug; })
		return countries.sort();
	}

	const customLabels = () => {
		let labels = {};
		languageState.possibleLanguages.forEach((lang) => { labels[lang.$flag_slug] = lang.$name })
		return labels;
	}

	const currentLanguageFlagSlug = () => {
		const local = LanguageUtils.getLanguage(props.eventId);
		if (local) {
			return local;
		}
		return languageState.currentLanguage.flag_slug;
	}

	useEffect(() => {
		if (!languageState.possibleLanguages || languageState.possibleLanguages.length == 0) {
			return;
		}

		handleLanguageParameter();
		if (props.hideLabels != undefined) {
			setShowLabels(!props.hideLabels);
		}
		if (props.direction != undefined) {
			setDirection(props.direction);
		}
	}, [languageState.currentLanguage, props.hideLabels]);

	return (
		<>
			<style>
				{direction == 'UP' && (
					`.header-language-selector ul {
                    top: -` + Math.min(languageState.possibleLanguages.length * 38 + 30, 190) + `px;
                }`)}
				{props.relative && (
					`#${selectorId} ul {
						position: relative;
					}`
				)}
				{`
				.header-language-selector button {
                    background: white;
                }
				.header-language-selector li {
					color: #7a7b97;
				}
				.header-language-selector li:hover {
					color: `+ props.eventColor + `;
					background-color: `+ ColorUtils.hexToRgba(props.eventColor, 0.15) + `;
				}
                `}
			</style>
			{(languageState.possibleLanguages.length > 1 && languageState.currentLanguage) && (
				<ReactFlagsSelect
					className="mx-3 header-language-selector"
					id={selectorId}
					showSelectedLabel={showLabels}
					showOptionLabel={showLabels}
					selected={currentLanguageFlagSlug()}
					onSelect={changeLanguage}
					countries={countries()}
					customLabels={customLabels()}
				/>
			)}
		</>
	);
};

export default HeaderLanguageSelector;
