import EventRoom from "./EventRoom";
import MetaTag from "./MetaTag";
import Speaker from "./Speaker";

export default class Session {
	private id: number | null;
	private title: string | null;
	private slug: string | null;
	private tag: string | null;
	private description: string | null;
	private start: string | null;
	private end: string | null;
	private event_id: number | null;
	private layout_settings: any | null;
	private layout: any | null;
	private speakers: Array<Speaker>;
	private rooms: Array<EventRoom>;
	private status: string | null;
	private show_in_timetable: boolean | null;
	private needs_registration: boolean | null;
	private can_access: boolean | null;
	private has_ended_layout: boolean | null;
	private ended_layout: any | null;
	private metaTag: MetaTag | null;

	constructor(
		id: number | null = null,
		title: string | null = null,
		slug: string | null = null,
		tag: string | null = null,
		description: string | null = null,
		start: string | null = null,
		end: string | null = null,
		event_id: number | null = null,
		layout_settings: any | null = null,
		layout: any | null = null,
		speakers: Array<Speaker> = [],
		rooms: Array<EventRoom> = [],
		status: string | null = null,
		show_in_timetable: boolean | null = null,
		needs_registration: boolean | null = null,
		can_access: boolean | null = null,
		metaTag: MetaTag | null = null,
		has_ended_layout: boolean | null = null,
		ended_layout: any | null = null,
    ) {
        this.id = id;
        this.title = title;
        this.slug = slug;
        this.tag = tag;
        this.description = description;
        this.start = start;
        this.end = end;
        this.event_id = event_id;
        this.layout_settings = layout_settings;
        this.layout = layout;
        this.speakers = speakers;
        this.show_in_timetable = show_in_timetable;
        this.needs_registration = needs_registration;
		this.rooms = rooms;
		this.status = status;
		this.can_access = can_access;
		this.metaTag = metaTag;
		this.has_ended_layout = has_ended_layout;
		this.ended_layout = ended_layout;
    }

    public static fromJSON(json: any): Session {
        var session: Session = new Session();

        Object.assign(session, json);
        session.$layout = Object.assign({}, json.layout);
        session.$layout_settings = JSON.parse(json.layout_settings);

		if (json.hasOwnProperty('ended_layout')) {
			session.$ended_layout = Object.assign({}, json.ended_layout);
		}
		
        if (json.speakers) {
            const u = [];
            for (let user of json.speakers) {
                u.push(Speaker.fromJSON(user));
            }
            session.$speakers = u;
        }

		if (json.rooms) {
			const r = [];
			for (let room of json.rooms) {
				r.push(EventRoom.fromJSON(room));
			}
			session.$rooms = r;
		}

		if (json.meta_tag) {
			session.$metaTag = MetaTag.fromJSON(json.meta_tag);
		}

		return session;
	}

	public toJSON(): any {
		return {
			id: this.id,
			title: this.title,
			slug: this.slug,
			tag: this.tag,
			description: this.description,
			start: this.start,
			end: this.end,
			event_id: this.event_id,
			layout_settings: JSON.stringify(this.layout_settings),
			layout: this.layout
		}
	}

	public get $id(): number | null {
		return this.id;
	}

	public get $title(): string | null {
		return this.title;
	}

	public get $slug(): string | null {
		return this.slug;
	}

	public get $tag(): string | null {
		return this.tag;
	}

	public get $description(): string | null {
		return this.description;
	}

	public get $start(): string | null {
		return this.start;
	}

	public get $end(): string | null {
		return this.end;
	}

	public get $event_id(): number | null {
		return this.event_id;
	}

	public get $layout_settings(): any | null {
		return this.layout_settings;
	}

	public get $layout(): any | null {
		return this.layout;
	}

	public get $speakers(): Array<Speaker> {
		return this.speakers;
	}
	public get $rooms(): Array<EventRoom> {
		return this.rooms;
	}
	public get $status(): string | null {
		return this.status;
	}
	public get $showInTimetable(): boolean | null {
		return this.show_in_timetable;
	}
	public get $needs_registration(): boolean | null {
		return this.needs_registration;
	}
	public get $can_access(): boolean | null {
		return this.can_access;
	}
	public get $has_ended_layout() : boolean | null {
		return this.has_ended_layout;
	}
	public get $ended_layout() : any | null {
		return this.ended_layout;
	}
	public get $metaTag(): MetaTag | null {
		return this.metaTag;
	}

	public set $id(id: number | null) {
		this.id = id;
	}

	public set $title(title: string | null) {
		this.title = title;
	}

	public set $slug(slug: string | null) {
		this.slug = slug;
	}

	public set $tag(tag: string | null) {
		this.tag = tag;
	}

	public set $description(description: string | null) {
		this.description = description;
	}

	public set $start(start: string | null) {
		this.start = start;
	}

	public set $end(end: string | null) {
		this.end = end;
	}

	public set $event_id(event_id: number | null) {
		this.event_id = event_id;
	}

	public set $layout_settings(layout_settings: any | null) {
		this.layout_settings = layout_settings;
	}

	public set $layout(layout: any | null) {
		this.layout = layout;
	}

    public set $speakers(speakers: Array<Speaker>) {
        this.speakers = speakers;
    }
    public set $rooms(rooms: Array<EventRoom>) {
        this.rooms = rooms;
    }
    public set $status(status: string | null) {
        this.status = status;
    }
    public set $show_in_timetable(show_in_timetable: boolean | null) {
        this.show_in_timetable = show_in_timetable;
    }
	public set $needs_registration(needs_registration: boolean | null) {
        this.needs_registration = needs_registration;
    }
	public set $has_ended_layout(has_ended_layout: boolean | null) {
        this.has_ended_layout = has_ended_layout;
    }
	public set $ended_layout(ended_layout: any | null) {
        this.ended_layout = ended_layout;
    }
	public set $metaTag(value: MetaTag | null) {
		this.metaTag = value;
	}
}