import axios from "axios"
import AuthService from "./AuthService"
import User from "../Models/User"

const UserService = {

	updateUser: async (eventId, user, extraFields = null) => {
		const obj = {};
		Object.assign(obj, user);
		obj['event_id'] = eventId;
		if(extraFields) {
			obj['extra_info'] = extraFields;
		}
		const response = await AuthService.put(
			"user/update", obj
		);

		return response;
	},
	uploadAvatar: async (imgData): Promise<string> => {
		const resp = await AuthService.post('user/avatar', {
			'type': 'profile_pictures',
			'data': imgData,
		});
		return resp.data.data;
	},
	iAmLoggedIn: async (eventId) => {
		await AuthService.post(`event/${eventId}/iAmLoggedIn`, {});
	},
	authenticate2FA: async (eventId, encrypted2FA) => {
		await AuthService.post(`event/${eventId}/authenticate2FA`, {data: encrypted2FA});
	}
}

export default UserService
