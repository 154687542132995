import React, { FC } from 'react';
import { Outlet, useOutlet } from 'react-router-dom';

interface Props {
	content: any;
}

const ParentRouteWithContent: FC<Props> = (props: Props) => {
	const outlet = useOutlet();

	if (outlet) {
		return <Outlet/>
	}

	return (
		<>{props.content}</>
	)
}
export default ParentRouteWithContent;