import React, { FC, useEffect, useRef } from 'react';
import { withSocket } from '../../HOC/Socket/withSocket';

interface RefresherProps {
	refreshTopic: string;
	onRefresh?: any;
}
const ComponentRefresher: FC<RefresherProps> = (props) => {
	const timer = useRef(0);

	useEffect(() => {
		initializeMqtt();

		return (() => clearTimeout(timer.current));
	}, [props.refreshTopic]);

	const initializeMqtt = async () => {
		//props.connectToBroker();
		props.subscribe("/refresh" + props.refreshTopic, handleIncoming, true)
	}

	const handleIncoming = (e) => {
		const message = JSON.parse(e.message);
		let time = 0;
		try {
			time = 1 + Math.random() * (message.max_wait_time - 1);

		} catch (e) {

		}

		timer.current = window.setTimeout(() => {
			if (props.onRefresh) {
				props.onRefresh();
				return;
			}
			window.location.reload();
		}, time * 1000)
	}

	return (
		<>

		</>
	);
};

export default withSocket(ComponentRefresher);
