import axios from 'axios';
import User from '../Models/User';

const AuthService = {
	/**
	 * Do a get request that is authenticated.
	 * @param {string} path The path for the request tot the API
	 * @throws error if the http response was incorrect.
	 */
	get: async function (path: string, params = {}): Promise<any> {
		const response = await axios.get(
			process.env.REACT_APP_API_URL + `${path}`,
			{
				params: params,
				headers: { Authorization: this._getJWT() },
			}
		);
		return response.data.data;
	},

	/**
	 * Send an authenticated put request.
	 * @param {string} path The path for the request tot the API
	 * @param payload The payload for the put request.
	 * @throws error if the http response was not 200/201
	 */
	put: async function (path: string, payload: any): Promise<any> {
		const response = await axios.put(
			process.env.REACT_APP_API_URL + `${path}`,
			payload,
			{
				headers: {
					Authorization: await this._getJWT(),
				},
			}
		);

		return response;
	},

	/**
	 * Send an authenticated post request.
	 * @param {string} path The path for the request tot the API
	 * @param payload The payload for the put request.
	 * @throws error if the http response was not 200/201
	 */
	post: async function (path: string, payload: any): Promise<any> {
		const response = await axios.post(
			process.env.REACT_APP_API_URL + `${path}`,
			payload,
			{
				headers: {
					Authorization: await this._getJWT(),
				},
			}
		);

		return response;
	},

	/**
	 * Send an authenticated patch request.
	 * @param {string} path The path for the request tot the API
	 * @param payload The payload for the put request.
	 * @throws error if the http response was not 200/201
	 */
	patch: async function (path: string, payload: any): Promise<any> {
		const response = await axios.patch(
			process.env.REACT_APP_API_URL + `${path}`,
			payload,
			{
				headers: {
					Authorization: await this._getJWT(),
				},
			}
		);

		return response;
	},

	/**
	 * Do a delete request that is authenticated.
	 * @param {string} path The path for the request tot the API
	 * @throws error if the http response was incorrect.
	 */
	delete: async function (path: string): Promise<any> {
		const response = await axios.delete(
			process.env.REACT_APP_API_URL + `${path}`,
			{
				headers: { Authorization: this._getJWT() },
			}
		);

		return response;
	},

	getMe: async function (): Promise<User> {
		const response = await axios.get(
			process.env.REACT_APP_API_URL + 'user/me',
			{
				headers: { Authorization: this._getJWT() },
			}
		);

		return User.fromJSON(response.data.data);
	},

	// login: async function (
	// 	email: string,
	// 	event: number,
	// 	password: string,
	// 	extra_data: Object
	// ): Promise<object> {
	// 	const payload = {
	// 		email: email,
	// 		payload: extra_data,
	// 		event: event,
	// 		password: password
	// 	};

	// 	const response = await axios
	// 		.post(process.env.REACT_APP_API_URL + 'event/login', payload);

	// 	if (response.status == 202) // Open registration successful
	// 		return response;

	// 	localStorage.setItem('_myAraToken', response.data.data['token']);
	// 	return response.data.data['user'];
	// },

	login: async (eventId: number, basicData: any = null, extraData: any = null, planData: any = null) => {
		const data = basicData;
		if (extraData) {
			data['extra_info'] = extraData;
		}
		if (planData) {
			data['participant_plan'] = planData;
		}

		const cookie = localStorage.getItem('user_cookie');
		if (cookie) {
			data['user_cookie'] = cookie;
		}

		const resp = await AuthService.post(`event/${eventId}/login`, data);
		return resp.data.data;
	},

	logout: function (): void {
		localStorage.removeItem('_myAraToken');
		// localStorage.removeItem('user_cookie');
	},

	isLoggedIn: function (): boolean {
		var token = localStorage.getItem('_myAraToken');
		if (!token) {
			return false;
		}

		return true;
	},

	/**
	 * Get a valid JWT. If the jwt expired, then a new one will be fetched in the background.
	 */
	_getJWT: function (): string {
		var token = 'Bearer ' + localStorage.getItem('_myAraToken');

		if (!token) {
			// NO TOKEN PROVIDED
			return '';
		}

		// TODO: check for valid token (if not renew)
		return token;
	},
};

export default AuthService;
