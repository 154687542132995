import React, { createContext } from 'react';
import { useReducerAsync } from 'use-reducer-async';
import LanguageReducer from '../reducers/LanguageReducer';
import Language from '../Models/Language';
import { asyncActionHandlers } from '../reducers/LanguageReducer'

function LanguageContext(reducer, initialState, asyncActionHandlers) {
	const Context = createContext(initialState);

	const Provider = ({ children }) => {
		const [languageState, languageDispatch] = useReducerAsync(reducer, initialState, asyncActionHandlers);

		return (
			<Context.Provider value={{ languageState: languageState, languageDispatch: languageDispatch }}>
				{children}
			</Context.Provider>
		);
	};
	return { Context, Provider };
}

export const { Context, Provider } = LanguageContext(
	LanguageReducer,
	{
		currentLanguage: new Language(),
		possibleLanguages: [],
		translations: {}
	},
	asyncActionHandlers
);