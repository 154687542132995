import React, { FC, useState, useEffect, lazy, Suspense, useContext } from 'react';
import EventBlock from '../../Models/EventBlock';
import { Row, Col, NavItem, NavLink as NavLinkStrap, Nav, Card, Container } from 'reactstrap';
import _uniqueId from 'lodash/uniqueId';
import LayoutService from '../../Services/LayoutService';
import { withSnackbar } from '../../HOC/Snackbar/withSnackbar';
import { Context as EventContext } from '../../context/EventContext';
import { Context as LanguageContext } from '../../context/LanguageContext';
import { Context as UserContext } from '../../context/UserContext';
import * as FontIcon from 'react-icons/fa'
import clsx from "clsx"
import MultipagerComponent from '../MultipagerComponent'
import { withSocket } from '../../HOC/Socket/withSocket'
import { toast, Bounce, Slide, Flip, Zoom } from "react-toastify"
import { useTranslation } from "react-i18next";

const importDemoBlock = (c) =>
	lazy(() =>
		import(`blocks/${c}/demo.tsx`)
	);
// Dynamic block code
const importBlock = (c) =>
	lazy(() =>
		import(`blocks/${c}`).catch((error) => {
			console.error(`COULD NOT IMPORT Block blocks/${c}`);
			console.error(error);
		})
	);
function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue(value => value + 1); // update the state to force render
}
const BlockList = ({ blocks }) => {
	var dynamicBlocks: Array<JSX.Element> = [];
	blocks.forEach((block) => {
		const Block = block.block;
		const className = block.name != "sponsor-block" ? block.name : "ara-sponso-block";
		dynamicBlocks.push(
			<Col key={_uniqueId('col-')} sm={{ size: block.column_width }} className="my-3">
				<Suspense fallback={''}>
					<div id={"event-block-container-" + block.id} className={"event-block-container " + className} style={{ height: '100%' }}>
						<Block
							zone="zone01"
							notificationTopic={block.notification_topic}
							updateTopic={block.update_topic}
							blockID={block.id}
							settings={block.settings}
							column_width={block.column_width}
							related={block.related}
						/>
					</div>
				</Suspense>
			</Col>
		);
	});

	return dynamicBlocks;
};
interface ZoneProps {
	blocks: Array<EventBlock>;
	layout?: any;
	color?: string;
}

const LayoutRenderer: FC<ZoneProps> = (props: ZoneProps) => {
	const { t } = useTranslation('header');
	const [dynBlocks, setDynBlocks] = useState([]);
	const { languageState, languageDispatch } = useContext(LanguageContext);
	const { state, dispatch } = useContext(EventContext);
	const { userState, userDispatch } = useContext(UserContext);
	const [layout, setLayout] = useState(undefined)
	const [activeTabs, setActiveTabs] = useState({});
	const forceUpdate = useForceUpdate();

	const lazyImportBlock = (path) => {
		let Block;
		if (state.event.$isDemo) {
			try {
				require.resolve(`blocks/${path}/demo.tsx`)
				Block = importDemoBlock(path)
			} catch (e) { }
		}

		if (!Block)
			Block = importBlock(path);

		return Block;
	}

	const init = async () => {
		setLayout(props.layout);
		const inHybridMode = localStorage.getItem('is_hybrid') == 'on';
		listenForNotifications(props.blocks);

		props.blocks.forEach(async (block) => {
			try {
				const Block = lazyImportBlock(block.$path)
				if (userState.user.$isWatchingAs && !block.$visible_for.includes(userState.user.$isWatchingAs)) {
					return
				}

				if ((block.$visible_in_hybrid && inHybridMode) || !inHybridMode) {
					const blockContent = {
						block: Block,
						id: block.$id,
						title: block.$dynamic_block.name,
						settings: block.$settings,
						column_width: block.$column_width,
						notification_topic: block.$notification_topic,
						update_topic: block.$update_topic,
						related: block.$models
					};
					setDynBlocks((dynBlocks) => [...dynBlocks, blockContent]);
				}


			} catch (error) {
			}
		});
	}
	const getBlockById = (id) => {
		for (let b of dynBlocks) {
			if (b.id == id) return b;
		}
	}

	const convertToSlug = (Text) => {
		if (!Text) return;
		Text = Text.toLowerCase();
		if (Text.includes("sponsor")) {
			Text = Text.replace("sponsor", "ara-spons")
		}
		return Text
			.toLowerCase()
			.replace(/ /g, '-')
			.replace(/[^\w-]+/g, '')
			;
	}
	const listenForNotifications = (blocks) => {
		try {

			props.subscribe("notification/" + state.event.$id + "/general", showToast.bind(null, "info-circle", t('moderator-notification-title')), true);

			if (!userState.user || !userState.user.$receive_notifications) return;

			for (let b of blocks) {
				if (b.$notification_topic) {
					props.subscribe(b.$notification_topic, showToast.bind(null, b.$icon, b.$title), true)
				}
			}

			if (userState.user.$paymentPlans) {
				for (let plan of userState.user.$paymentPlans) {
					props.subscribe("notification/" + state.event.$id + "/type/" + plan.$id, showToast.bind(null, "info-circle", t('moderator-notification-title')), true);
				}
			}
		} catch (e) {
			console.error(e)
		}
	}
	const showToast = (icon, title, msg) => {
		msg = JSON.parse(msg.message);


		if (msg.show_msg) {
			props.openSnackbar(icon, title, msg.msg)
		}

	}

	const renderBlock = (blockId, columnWidth) => {
		const block = getBlockById(blockId);
		if (!block) return;
		const Block = block.block;

		if (process.env.REACT_APP_ENV === 'dev') {
			return (
				<div id={"event-block-container-" + block.id} key={"event-block-container-" + block.id} className={"event-block-container py-3 " + convertToSlug(block.title)}>
					<Block
						zone="zone01"
						notificationTopic={block.notification_topic}
						updateTopic={block.update_topic}
						blockID={block.id}
						settings={block.settings}
						column_width={columnWidth}
						related={block.related}
					/>
				</div>
			)
		} else {
			return (
				<Suspense fallback={''} key={'event-block-' + block.id}>
					<div id={"event-block-container-" + block.id} className={"event-block-container py-3 " + convertToSlug(block.title)}>
						<Block
							zone="zone01"
							notificationTopic={block.notification_topic}
							updateTopic={block.update_topic}
							blockID={block.id}
							settings={block.settings}
							column_width={columnWidth}
							related={block.related}
						/>
					</div>
				</Suspense>
			)
		}
	}

	useEffect(() => {
		init();
	}, [props.layout, props.blocks]);

	return (
		<>
			<style>
				{`
			.event-nav-tabs .nav.nav-line .nav-item .nav-link {
				border-bottom: 3px solid transparent;
			}
			.event-nav-tabs .nav.nav-line .nav-item .nav-link.active {
				color: ` + props.color ? props.color : state.event.$color + `;
				border-bottom: 3px solid ` + state.event.$color + `;
			}
			`}
			</style>
			{layout ? (
				<>
					<Container fluid>
						{layout.map((row, rowIndex) => {
							return (
								<Row key={'layout-row-' + rowIndex}>
									{row.map((col, colIndex) => {
										if (!col.column_type || col.column_type == 'default') {
											let containerClasses = "";
											for (let b of col.blocks) {
												containerClasses += "block-" + b.event_block_id + "-column ";
											}
											return (
												<Col sm={col.column_width} className={containerClasses} key={'layout-row-' + rowIndex + '-col-' + colIndex}>
													{col.blocks.map((block, blockIndex) => {
														return renderBlock(block.event_block_id, col.column_width)
													})}
												</Col>
											)
										}
										if (col.column_type == 'tabs') {
											return (
												<Col sm={col.column_width} className="d-flex flex-column" key={'layout-row-' + rowIndex + '-col-' + colIndex}>
													<MultipagerComponent
														tabs={col.blocks}
														eventBlocks={dynBlocks}
														id={'exhibition-multipager-row-' + rowIndex + '-col-' + colIndex}
													/>
												</Col>
											)
										}
									})}
								</Row>
							)
						})}
					</Container>

				</>
			) : (
				<div>
					{process.env.REACT_APP_ENV === 'dev' ? (
						<Row className="justify-content-center">
							<BlockList blocks={dynBlocks}></BlockList>
						</Row>
					) : (
						<React.Suspense fallback="">
							<Row className="justify-content-center">
								<BlockList blocks={dynBlocks}></BlockList>
							</Row>

						</React.Suspense>
					)}

				</div>
			)
			}

		</>
	);
};

export default withSnackbar(withSocket(LayoutRenderer));
