import MetaTag from './MetaTag';
import User from './User'
export default class Exhibition {
	private id: number | null;
	private title: string | null;
	private slug: string | null;
	private event_id: number | null;
	private layout_settings: any | null;
	private layout: any | null;
	private logo: string | null;
	private users: Array<User> | null;
	private tags: Array<string> | null;
	private description: string | null;
	private metaTag: MetaTag | null;

	constructor(
		id: number | null = null,
		title: string | null = null,
		slug: string | null = null,
		event_id: number | null = null,
		layout_settings: any | null = null,
		layout: any | null = null,
		logo: string | null = null,
		users: Array<User> = [],
		tags: Array<string> | null = [],
		description: string | null = null,
		metaTag: MetaTag | null = null,
	) {
		this.id = id;
		this.title = title;
		this.slug = slug;
		this.event_id = event_id;
		this.layout_settings = layout_settings;
		this.layout = layout;
		this.logo = logo;
		this.users = users;
		this.tags = tags;
		this.description = description;
		this.metaTag = metaTag;
	}

	public static fromJSON(json: any): Exhibition {
		var exhibition: Exhibition = new Exhibition();
		Object.assign(exhibition, json);
		exhibition.$layout = Object.assign({}, json.layout);
		exhibition.$layout_settings = JSON.parse(json.layout_settings);
		if (json.tags) {
			exhibition.$tags = JSON.parse(json.tags)

		}

		const u = [];
		if (json.users) {
			for (let user of json.users) {
				u.push(User.fromJSON(user));
			}
		}
		exhibition.$users = u;

		if (json.meta_tag) {
			exhibition.$metaTag = MetaTag.fromJSON(json.meta_tag);
		}

		return exhibition;
	}

	public get $id(): number | null {
		return this.id;
	}
	public get $title(): string | null {
		return this.title;
	}
	public get $event_id(): number | null {
		return this.event_id;
	}
	public get $layout_settings(): any | null {
		return this.layout_settings;
	}
	public get $layout(): any | null {
		return this.layout;
	}
	public get $logo(): string | null {
		return this.logo;
	}
	public get $slug(): string | null {
		return this.slug;
	}
	public get $users(): Array<User> | null {
		return this.users;
	}
	public get $tags(): Array<string> | null {
		return this.tags;
	}
	public get $description(): string | null {
		return this.description;
	}
	public get $metaTag(): MetaTag | null {
		return this.metaTag;
	}

	public set $id(id: number | null) {
		this.id = id;
	}
	public set $title(title: string | null) {
		this.title = title;
	}
	public set $event_id(event_id: number | null) {
		this.event_id = event_id;
	}
	public set $layout_settings(layout_settings: any | null) {
		this.layout_settings = layout_settings;
	}
	public set $layout(layout: any | null) {
		this.layout = layout;
	}
	public set $logo(logo: string | null) {
		this.logo = logo;
	}
	public set $slug(slug: string | null) {
		this.slug = slug;
	}
	public set $users(users: Array<User> | null) {
		this.users = users;
	}
	public set $tags(tags: Array<string> | null) {
		this.tags = tags;
	}
	public set $description(description: string | null) {
		this.description = description;
	}
	public set $metaTag(value: MetaTag | null) {
		this.metaTag = value;
	}
}