import { Reducer } from 'react';
import Event from '../Models/Event';
import EventService from '../Services/EventService';
import { AsyncActionHandlers } from 'use-reducer-async';
import PreviewService from '../Services/PreviewService';
import LanguageUtils from '../utils/LanguageUtils';

const loadEvent = async (
	url: String,
	organizationSlug: String,
	eventSlug: String,
	languageSlug: String
) => {
	if (!languageSlug) {
		languageSlug = LanguageUtils.getLanguageBySlug(eventSlug, organizationSlug)
	}
	try {
		var event = await EventService.getWithSlug(
			url,
			organizationSlug,
			eventSlug,
			languageSlug
		);

		return event;
	} catch (err) {
		const response = err.response.data;
		console.error("Could not fetch event")
		throw new Error(response.code);
	}
};

const goToLogin = (organisationSlug, eventSlug) => {
	var loginUrl = `/${organisationSlug}/${eventSlug}/login?`;

	if (!organisationSlug || !eventSlug) {
		loginUrl = '/login?';
	}

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	urlParams.forEach((val, key) => {
		loginUrl += key + "=" + val + "&"
	})

	if (!window.location.href.includes('/login')) {
		window.location.href = loginUrl;
	}
}



type State = {
	event: Event;
	loading: boolean;
};

type Action =
	| { type: 'START_FETCH' }
	| { type: 'END_FETCH'; event: Event }
	| { type: 'ERROR_FETCH'; code: String };

type AsyncAction = {
	type: 'FETCH';
	url: String;
	organisationSlug: String;
	eventSlug: String;
	languageSlug: String;
	callback: Function;
	errorCallback: Function;
};

type SetAction = {
	type: 'SET_EVENT';
	event: Event;
	callback: Function;
	errorCallback: Function;
}

const asyncActionHandlers: AsyncActionHandlers<Reducer<State, Action>, AsyncAction | SetAction> = {
	FETCH: ({ dispatch }) => async (action) => {
		dispatch({ type: 'START_FETCH' });
		try {
			const event = await loadEvent(
				action.url,
				action.organisationSlug,
				action.eventSlug,
				action.languageSlug,
			);

			if (action.callback)
				action.callback(event.$template.path, event.$favicon, event.$title, event.$translation_data, event);
			dispatch({ type: 'END_FETCH', event: event });
		} catch (e) {
			if (action.errorCallback)
				action.errorCallback(e.message);
			dispatch({ type: 'ERROR_FETCH', code: e.message });
		}
	},
	SET_EVENT: ({ dispatch }) => async (action) => {
		dispatch({ type: 'START_FETCH' });
		try {
			dispatch({ type: 'END_FETCH', event: action.event })
			if (action.callback)
				action.callback(action.event.$template.path,
					action.event.$favicon,
					action.event.$title,
					action.event.$translation_data,
					action.event);
		} catch (e) {
			if (action.errorCallback) {
				action.errorCallback(e.message);
			}
			dispatch({ type: 'ERROR_FETCH', code: e.message });
		}
	}
};

const EventReducer = (state, action) => {
	switch (action.type) {
		case 'START_FETCH':
			return {
				...state,
				loading: true,
			};
		case 'END_FETCH':
			return {
				...state,
				loading: false,
				event: action.event,
				error: false,
			};
		case 'ERROR_FETCH':
			return {
				...state,
				// event: null,
				loading: false,
				code: action.code,
				error: true,
			};
		default:
			return { ...state };
	}
};

export default EventReducer;
export { asyncActionHandlers };
