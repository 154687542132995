
import React, { useState, useRef, useEffect, lazy, useContext, FC, Suspense } from 'react';
import {
	Button,
	Col,
	UncontrolledTooltip,
	UncontrolledDropdown,
	DropdownMenu,
	ListGroup,
	ListGroupItem,
	DropdownToggle,
	NavLink as NavLinkStrap,
	Nav,
	NavItem,
} from 'reactstrap'
import _uniqueId from 'lodash/uniqueId';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PerfectScrollbar from "react-perfect-scrollbar"
import { withSocket } from '../../../HOC/Socket/withSocket'
import { Context as EventContext } from '../../../context/EventContext';
import EventBlock from '../../../Models/EventBlock'
import clsx from "clsx"
import LayoutService from '../../../Services/LayoutService';
import HeaderHybridSelector from '../../../layout-components/HeaderHybridSelector';
import HeaderLanguageSelector from '../../../layout-components/HeaderLanguageSelector';
import { useTranslation } from "react-i18next";
import AnalyticsService from '../../../Services/AnalyticsService';
import { Context as UserContext } from '../../../context/UserContext';
import AuthService from '../../../Services/AuthService';
import ColorUtils from '../../../utils/ColorUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import NavigateUtils from '../../../utils/NavigateUtils';
import { useNavigate } from 'react-router';

function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue(value => value + 1); // update the state to force render
}
interface Props {
	color: string;
	onSidebarToggle?: any;
	hideBubble: boolean;
}

const Zone02Sidebar: FC<Props> = (props) => {
	const { state, display } = useContext(EventContext)
	const [headerDrawerToggle, setHeaderDrawerToggle] = useState(false)
	const headerDrawerToggleRef = useRef(headerDrawerToggle);
	const [sidebarWidth, setSidebarWidth] = useState(450)
	const [blocks, setBlocks] = useState([]);
	const [sidebarAnchor, setSidebarAnchor] = useState("0")
	const forceUpdate = useForceUpdate();
	const [buttons, setButtons] = useState<Array<EventBlock>>([])
	const buttonsRef = useRef(buttons)
	const [activeBlock, setActiveBlock] = useState(0);
	const activeBlockRef = useRef(activeBlock);
	const [notifications, setNotifications] = useState({});
	const { t, i18n } = useTranslation('header');
	const { userState, userDispatch } = useContext(UserContext);
	const reactNavigate = useNavigate();

	const timer = useRef(0);

	const fetchLayout = async () => {
		const l = state.event.$navigation.sidebar;
		if (l) {
			const arr = [];
			for (let block of l.blocks) {
				const b = EventBlock.fromJSON(block);
				arr.push(b);
			}
			setButtons(arr);
			buttonsRef.current = arr;

			const state = arr.map((x, index) => (renderBlock(x)));
			setBlocks(state)
		} else {
			const layouts = await LayoutService.getFrontendPanels(state.event.$id, LanguageUtils.getLanguage(state.event.$id));
			for (let l of layouts) {
				if (l.event_template_zone_id == 2 && l.event_state_id == state.event.$current_state.id) {
					const arr = [];
					for (let block of l.blocks) {
						const b = EventBlock.fromJSON(block);
						arr.push(b);
					}
					setButtons(arr);
					buttonsRef.current = arr;

					const state = arr.map((x, index) => (renderBlock(x)));
					setBlocks(state)
				}
			}
		}
	}

	const toggleDrawer = () => {
		if (window.innerWidth <= 1099.98) setSidebarWidth(window.innerWidth)
		if (props.onSidebarToggle) {
			props.onSidebarToggle(!headerDrawerToggle, sidebarWidth);
			timer.current = window.setTimeout(() => {
				setHeaderDrawerToggle(!headerDrawerToggle)
				headerDrawerToggleRef.current = !headerDrawerToggle;
			}, 100)
		} else {
			setHeaderDrawerToggle(!headerDrawerToggle)
			headerDrawerToggleRef.current = !headerDrawerToggle;
		}
	}

	const importBlock = (c) =>
		lazy(() =>
			import(`../../../blocks/${c}`).catch((error) => {
				console.error(`COULD NOT IMPORT Block ${c}`);
				console.error(error);
			})
		);

	function handleWindowSizeChange() {
		if (window.innerWidth <= 1099.98) {
			setSidebarWidth(window.innerWidth);
			setSidebarAnchor("0")
		} else {
			setSidebarWidth(450);
			setSidebarAnchor("0")
		}
	}

	useEffect(() => {
		fetchLayout();

		handleWindowSizeChange();
		window.addEventListener('resize', handleWindowSizeChange);
		window.addEventListener('toggle-sidebar', handleIncomingTrigger);

		listenForNotifications();

		return (() => clearTimeout(timer.current));
	}, []);

	const handleIncomingTrigger = (e) => {
		for (let i = 0; i < buttonsRef.current.length; i++) {
			const block = buttonsRef.current[i];
			if (block.id == e.detail.block_id) {
				activeBlockRef.current = i;
				setActiveBlock(i)
				toggleDrawer()
				if (!headerDrawerToggleRef.current) {
				}
				break;
			}
		}
		forceUpdate();
	}

	const listenForNotifications = () => {
		let blockIndex = 0;
		for (const block of buttons) {
			notifications[block.$id] = false;
			if (block.$notification_topic) {
				props.subscribe(block.$notification_topic, showToast.bind(null, block.$icon, block.$title), true)
				props.subscribe(block.$notification_topic, handleNotification.bind(null, block, blockIndex))
			}
			blockIndex++;
		}
		setNotifications(notifications);
	}

	const handleNotification = (block, blockIndex, msg) => {
		msg = JSON.parse(msg.message);
		if (msg.show) {
			notifications[block.$id] = true;
			setNotifications(notifications);
		}
		forceUpdate();
	}

	const showToast = (icon, title, msg) => {
		if (!userState.user || !userState.user.$receive_notifications) return;

		msg = JSON.parse(msg.message);
		icon = icon.replace("fas-", "")

		if (msg.show_msg) {
			props.openSnackbar(icon, title, msg.msg);
		}
	}

	const onBlockNotification = (blockId) => {
		notifications[blockId] = true;
		setNotifications(notifications);
		forceUpdate();
	}

	const renderBlock = (block) => {
		if (!block) return;

		const Block = importBlock(block.$path);

		const inHybridMode = localStorage.getItem('is_hybrid') == 'on';

		if (!inHybridMode || (inHybridMode && block.$visible_in_hybrid)) {
			return (
			<Col key={_uniqueId('col-')} sm={12} className="py-3" style={{ height: '100%' }}>
				<Suspense fallback={''}>
					<div id={"event-block-container-" + block.id} className="event-block-container" style={{ height: '100%' }}>
						<Block
							zone="zone02"
							notificationTopic={block.$notification_topic}
							blockID={block.$id}
							settings={block.settings}
							column_width={block.column_width}
							onNotification={onBlockNotification.bind(null, block.$id)}
							related={block.related}
						/>
					</div>
				</Suspense>
			</Col >
			);
		}
	}

	const logout = () => {
		AnalyticsService.post(
			{ event: 'LOGGED OUT' },
			window.location.pathname,
			state.event.id,
			userState.user.id,
			'auth'
		);

		AuthService.logout();
		window.location.reload();
	};

	const style = () => {
		let s = `
        .custom-hamburger-inner {
            color: ` + props.color + ` !important;
            background: ` + props.color + ` !important;
        }
        .custom-hamburger-inner::after {
            background: ` + props.color + ` !important;
        }
        .custom-hamburger-inner::before {
            background: ` + props.color + ` !important;
        }
        .custom-badge {
            position: absolute !important;
            top: 0px !important;
            right: 0px !important;
        }
        .floating-profile {
            background-color: ` + props.color + `;
            color: ` + ColorUtils.contrastColor(props.color) + `;
            border-color: ` + props.color + `;
            border-radius: 35px;
            width: 70px;
            height: 70px;
            position: fixed;
            right: 30px;
            top: 30px;
            z-index: 1000
        }
        .floating-profile:hover {
            color: ` + props.color + `;
            background-color: ` + ColorUtils.contrastColor(props.color) + `;
        }
        `
		if (headerDrawerToggle) {
			s += `
                .modal {
                    padding-right: ` + sidebarWidth + `px !important;
                }

                .floating-profile {
                    right: ` + (sidebarWidth + 30) + `px !important;
                }
            `
		}
		return s;
	}

	return (
		<>
			<style>
				{style()}
			</style>

			{!props.hideBubble && (

				<UncontrolledDropdown>
					<DropdownToggle size="sm" outline color="#ff0000" className="d-flex align-items-center justify-content-center floating-profile">
						<FontAwesomeIcon icon={['fas', 'user']} size="lg" />
					</DropdownToggle>
					<DropdownMenu
						right
						className="dropdown-menu-lg overflow-hidden p-0"
					>
						<ListGroup flush className="text-left bg-transparent">
							<ListGroupItem className="rounded-top">
								<Nav
									pills
									className="nav-neutral-primary flex-column"
								>
									<NavItem>
										<NavLinkStrap onClick={(e) => logout()}>
											<FontAwesomeIcon
												icon={['fas', 'sign-out-alt']}
												className="opacity-5 mr-2"
											/>
											Log out
										</NavLinkStrap>
									</NavItem>
									<NavItem>
										<NavLinkStrap onClick={() => {
											NavigateUtils.navigate(
												reactNavigate,
												state.event,
												'profile'
											);
										}}>
											<FontAwesomeIcon
												icon={['fa', 'user']}
												className="opacity-5 mr-2"
											/>{t('profile')} {' '}
										</NavLinkStrap>
									</NavItem>

									{state.event.hasService('hybrid') && (
										<NavItem>
											<NavLinkStrap>
												<HeaderHybridSelector />
											</NavLinkStrap>
										</NavItem>
									)}

									<NavItem>
										<NavLinkStrap>
											<HeaderLanguageSelector
												eventSlug={state.event.$slug}
												organisationSlug={state.event.$organisation.slug}
												eventId={state.event.$id}
												hideLabels={false}
												eventColor={state.event.$color}
											/>
										</NavLinkStrap>
									</NavItem>
								</Nav>
							</ListGroupItem>
						</ListGroup>
					</DropdownMenu>
				</UncontrolledDropdown>
			)}

			<div className={clsx("app-drawer-content", { "header-drawer-open": headerDrawerToggle, })} style={{ transform: headerDrawerToggle ? "translateX(" + sidebarAnchor + ")" : "translateX(" + sidebarWidth + "px)" }}>
				<Button
					onClick={toggleDrawer}
					className="close-drawer-btn btn btn-sm"
					id="CloseDrawerTooltip"
				>
					<div
						className={clsx("navbar-toggler hamburger hamburger--elastic", {
							"is-active": headerDrawerToggle,
						})}
					>
						<span className="hamburger-box">
							<span className="custom-hamburger-inner hamburger-inner" style={{ color: props.color, backgroundColor: props.color + ' !important' }} />
						</span>

					</div>
				</Button>
				<UncontrolledTooltip target="CloseDrawerTooltip" placement="left">
					{t('close-drawer')}
				</UncontrolledTooltip>
				<div className="vh-100 shadow-overflow">
					<PerfectScrollbar className="mt-5">
						{blocks.map((block, blockIndex) => {
							if (!headerDrawerToggle || blockIndex == activeBlockRef.current)
								return block;
						})}
					</PerfectScrollbar>
				</div>
			</div >

			<div
				onClick={toggleDrawer}
				className={clsx("app-drawer-overlay", {
					"is-active": false,
				})}
			/>
		</>
	);
};

export default withSocket(Zone02Sidebar)