import React, { Component } from 'react';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Router from './Router';
import { ToastContainer, toast } from 'react-toastify';
import { Provider as EventProvider } from './context/EventContext';
import { Provider as UserProvider } from './context/UserContext';
import { Provider as LanguageProvider } from './context/LanguageContext'
import './assets/base.scss';
import { default as Socket } from "./HOC/Socket/Socket";
import { default as Snackbar } from "./HOC/Snackbar/Snackbar";
import { default as Notifications, NotificationsContext } from "./HOC/Notifications/Notifications";
import { library } from '@fortawesome/fontawesome-svg-core';
import 'react-toastify/dist/ReactToastify.css';

import {
	fab,
	faFacebook,
	faTwitter,
	faVuejs,
	faReact,
	faHtml5,
	faGoogle,
	faInstagram,
	faPinterest,
	faYoutube,
	faDiscord,
	faSlack,
	faDribbble,
	faGithub,
} from '@fortawesome/free-brands-svg-icons';
import {
	far,
	faSquare,
	faLifeRing,
	faCheckCircle,
	faTimesCircle,
	faDotCircle,
	faThumbsUp,
	faComments,
	faFolderOpen,
	faTrashAlt,
	faFileImage,
	faFileArchive,
	faCommentDots,
	faFolder,
	faKeyboard,
	faCalendarAlt,
	faEnvelope,
	faAddressCard,
	faMap,
	faObjectGroup,
	faImages,
	faUser,
	faLightbulb,
	faGem,
	faClock,
	faUserCircle,
	faQuestionCircle,
	faBuilding,
	faBell,
	faFileExcel,
	faFileAudio,
	faFileVideo,
	faFileWord,
	faFilePdf,
	faFileCode,
	faFileAlt,
	faEye,
	faChartBar,
} from '@fortawesome/free-regular-svg-icons';
import {
	fas,
	faExclamation,
	faAngleDoubleRight,
	faAngleDoubleLeft,
	faCheck,
	faSmile,
	faHeart,
	faBatteryEmpty,
	faBatteryFull,
	faChevronRight,
	faSitemap,
	faPrint,
	faMapMarkedAlt,
	faTachometerAlt,
	faAlignCenter,
	faExternalLinkAlt,
	faShareSquare,
	faInfoCircle,
	faSync,
	faQuoteRight,
	faStarHalfAlt,
	faShapes,
	faCarBattery,
	faTable,
	faCubes,
	faPager,
	faCameraRetro,
	faBomb,
	faNetworkWired,
	faBusAlt,
	faBirthdayCake,
	faEyeDropper,
	faUnlockAlt,
	faDownload,
	faAward,
	faPlayCircle,
	faReply,
	faUpload,
	faBars,
	faEllipsisV,
	faSave,
	faSlidersH,
	faCaretRight,
	faChevronUp,
	faPlus,
	faLemon,
	faChevronLeft,
	faTimes,
	faChevronDown,
	faFilm,
	faSearch,
	faEllipsisH,
	faCog,
	faArrowsAltH,
	faPlusCircle,
	faAngleRight,
	faAngleUp,
	faAngleLeft,
	faAngleDown,
	faArrowUp,
	faArrowDown,
	faArrowRight,
	faArrowLeft,
	faStar,
	faSignOutAlt,
	faLink,
} from '@fortawesome/free-solid-svg-icons';
import AraRouter from './pages/AraRouter';
import AraPreviewRouter from './pages/AraPreviewRouter';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import StandardErrorBoundary from './HOC/StandardErrorBoundary';
library.add(
	far,
	faSquare,
	faLifeRing,
	faCheckCircle,
	faTimesCircle,
	faDotCircle,
	faThumbsUp,
	faComments,
	faFolderOpen,
	faTrashAlt,
	faFileImage,
	faFileArchive,
	faCommentDots,
	faFolder,
	faKeyboard,
	faCalendarAlt,
	faEnvelope,
	faAddressCard,
	faMap,
	faObjectGroup,
	faImages,
	faUser,
	faLightbulb,
	faGem,
	faClock,
	faUserCircle,
	faQuestionCircle,
	faBuilding,
	faBell,
	faFileExcel,
	faFileAudio,
	faFileVideo,
	faFileWord,
	faFilePdf,
	faFileCode,
	faFileAlt,
	faEye,
	faChartBar
);
library.add(
	fab,
	faFacebook,
	faTwitter,
	faVuejs,
	faReact,
	faHtml5,
	faGoogle,
	faInstagram,
	faPinterest,
	faYoutube,
	faDiscord,
	faSlack,
	faDribbble,
	faGithub
);
library.add(
	fas,
	faExclamation,
	faAngleDoubleRight,
	faAngleDoubleLeft,
	faCheck,
	faSmile,
	faHeart,
	faBatteryEmpty,
	faBatteryFull,
	faChevronRight,
	faSitemap,
	faPrint,
	faMapMarkedAlt,
	faTachometerAlt,
	faAlignCenter,
	faExternalLinkAlt,
	faShareSquare,
	faInfoCircle,
	faSync,
	faQuoteRight,
	faStarHalfAlt,
	faShapes,
	faCarBattery,
	faTable,
	faCubes,
	faPager,
	faCameraRetro,
	faBomb,
	faNetworkWired,
	faBusAlt,
	faBirthdayCake,
	faEyeDropper,
	faUnlockAlt,
	faDownload,
	faAward,
	faPlayCircle,
	faReply,
	faUpload,
	faBars,
	faEllipsisV,
	faSave,
	faSlidersH,
	faCaretRight,
	faChevronUp,
	faPlus,
	faLemon,
	faChevronLeft,
	faTimes,
	faChevronDown,
	faFilm,
	faSearch,
	faEllipsisH,
	faCog,
	faArrowsAltH,
	faPlusCircle,
	faAngleRight,
	faAngleUp,
	faAngleLeft,
	faAngleDown,
	faArrowUp,
	faArrowDown,
	faArrowRight,
	faArrowLeft,
	faStar,
	faSignOutAlt,
	faLink
);
const store = configureStore();
class App extends Component {
	render() {
		return (
			<React.Suspense fallback="">
				<Provider store={store}>
					<StandardErrorBoundary>
						<LanguageProvider>
							<UserProvider>
								<EventProvider>
									<Socket>
										<Snackbar>
											<BrowserRouter>
												{process.env.REACT_APP_ENV == 'preview' ? (
													<AraPreviewRouter />
												) : (
													<AraRouter />
												)}
											</BrowserRouter>
											<ToastContainer
												enableMultiContainer
												containerId={'A'}
												position={toast.POSITION.BOTTOM_LEFT}
											/>
											<ToastContainer
												enableMultiContainer
												containerId={'B'}
												position={toast.POSITION.TOP_RIGHT}
											/>
											<ToastContainer
												enableMultiContainer
												containerId={'C'}
												position={toast.POSITION.BOTTOM_CENTER}
											/>
										</Snackbar>
									</Socket>
								</EventProvider>
							</UserProvider>
						</LanguageProvider>
					</StandardErrorBoundary>
				</Provider>
			</React.Suspense>

		);
	}
}

export default App;
