import React, { createContext, FC } from 'react';
import "./style/snackbar.scss";
//import { faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
//import './styles/NTSnackbar.scss';


export const SnackbarContext = createContext({});


interface SnackbarProps {
	children: any
}

const Snackbar: FC<SnackbarProps> = ({ children }) => {


	const openSnackbar = (icon: string, title: string, message: string) => {

		const e = new CustomEvent('show-ara-notification', {
			detail: {
				icon: icon,
				title: title,
				message: message
			}
		});
		window.dispatchEvent(e);
	}

	return (
		<SnackbarContext.Provider value={{ openSnackbar: openSnackbar }}>
			{children}
		</SnackbarContext.Provider>
	)
}

export default Snackbar;