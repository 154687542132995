import React from 'react';
import { SnackbarContext } from './Snackbar';

export function withSnackbar(WrappedComponent: any) {

	return class extends React.Component {
		static contextType = SnackbarContext

		constructor(props: any) {
			super(props)
			this.open = this.open.bind(this)
		}

		open(icon: string, title: string, message: string) {
			const { openSnackbar }: any = this.context
			openSnackbar(icon, title, message)
		}

		render() {
			return (
				<WrappedComponent
					openSnackbar={this.open}
					{...this.props}
				/>
			)
		}

	}
}