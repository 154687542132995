import MetaTag from './MetaTag';
import Session from './Session';

export default class EventRoom {
	private id: number | null;
	private title: string | null;
	private slug: string | null;
	private logo: string | null;
	private layout: any | null;
	private layout_settings: any | null;
	private event_id: number | null;
	private session: Session | null;
	private can_access: boolean | null;
	private flip_layout: boolean | null;
	private metaTag: MetaTag | null;

	constructor(
		id: number | null = null,
		title: string | null = null,
		slug: string | null = null,
		logo: string | null = null,
		layout: any | null = null,
		layout_settings: any | null = null,
		event_id: number | null = null,
		session: Session | null = null,
		can_access: boolean | null = null,
		flip_layout: boolean | null = null,
		metaTag: MetaTag | null = null,
	) {
		this.id = id;
		this.title = title;
		this.slug = slug;
		this.logo = logo;
		this.layout = layout;
		this.layout_settings = layout_settings;
		this.event_id = event_id;
		this.session = session;
		this.can_access = can_access;
		this.flip_layout = flip_layout;
		this.metaTag = metaTag;
	}

	public static fromJSON(json: any): EventRoom {
		var eventRoom: EventRoom = new EventRoom();
		Object.assign(eventRoom, json);
		eventRoom.$layout = Object.assign({}, json.layout);
		eventRoom.$layout_settings = JSON.parse(json.layout_settings);
		if (json.active_sessions && json.active_sessions.length > 0) {
			eventRoom.$session = Session.fromJSON(json.active_sessions[0]);
		}
		if (json.session) {
			eventRoom.$session = Session.fromJSON(json.session);
		}
		if (json.meta_tag) {
			eventRoom.$metaTag = MetaTag.fromJSON(json.meta_tag)
		}

		return eventRoom;
	}

	public get $flip_layout(): boolean | null { return this.flip_layout }
	public get $id(): number | null { return this.id; }
	public get $can_access(): boolean | null { return this.can_access; }
	public set $can_access(can_access: boolean | null) { this.can_access = can_access; }
	public get $title(): string | null {
		return this.title;
	}

	public get $slug(): string | null {
		return this.slug;
	}

	public get $logo(): string | null {
		return this.logo;
	}

	public get $layout(): any | null {
		return this.layout;
	}

	public get $layout_settings(): any | null {
		return this.layout_settings;
	}

	public get $event_id(): number | null {
		return this.event_id;
	}

	public set $session(value: Session | null) {
		this.session = value;
	}

	public get $session(): Session | null {
		return this.session;
	}

	public get $metaTag(): MetaTag | null {
		return this.metaTag;
	}

	public set $id(value: number | null) {
		this.id = value;
	}

	public set $title(value: string | null) {
		this.title = value;
	}

	public set $slug(value: string | null) {
		this.slug = value;
	}

	public set $logo(value: string | null) {
		this.logo = value;
	}

	public set $layout(value: any | null) {
		this.layout = value;
	}

	public set $layout_settings(value: any | null) {
		this.layout_settings = value;
	}

	public set $event_id(value: number | null) {
		this.event_id = value;
	}

	public set $metaTag(value: MetaTag | null) {
		this.metaTag = value;
	}

}