import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Row,
  Col,
  UncontrolledPopover,
  Badge,
  Nav,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import BreakoutRooms from "../../components/BreakoutRooms"
import ModalDialog from "../../components/ModalDialog"
import { NavLink as NavLinkStrap } from "reactstrap"

import { Settings, Grid, Users, Layers } from "react-feather"

const HeaderMenu = () => {
  return (
    <>
      <div className="app-header-menu">
        <span className="position-relative"></span>
      </div>
    </>
  )
}

export default HeaderMenu
