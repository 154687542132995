export default class ColorUtils {
	/**
	 * Convert hexadecimal color to rgba form
	 * @param hex Hexadecimal color
	 * @param opacity Opacity (or alpha) of rgba
	 * @returns Rgba color
	 */
	static hexToRgba(hex: string, opacity: number = 1): any {
		if(!hex) return '';
        if (hex.length == 4) {
            hex = hex.split('').map(function (hex) {
                return hex + hex;
            }).join('');
            hex = hex.replace('##', '#')

        }
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? 'rgba(' + parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) + ', ' + opacity + ')'
            : '';
	}

	/**
	 * Convert hexadecimal color to rgb form
	 * @param hex Hexadecimal color
	 * @param alpha Alpha of the rgb
	 * @returns rgb color
	 */
	static hexToRgb(hex: string): any {
		// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
		let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		hex = hex.replace(shorthandRegex, function (_, r, g, b) {
			return r + r + g + g + b + b;
		});

		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}

	/**
	 * Get the contrasting color
	 * @param hexColor Color in hexadecimal form
	 * @returns Color in hexadecimal form
	 */
	static contrastColor(hexColor: string): any {
		try {
			const rgb: any = this.hexToRgb(hexColor);
			const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
			if (luminance > 0.7) {
				return "#000000"
			} else {
				return "#ffffff"
			}
		} catch (e) {
			return "#000000"
		}
	}
}
