import TemplateZone from "./TemplateZone";
import Exhibition from "./Exhibition";
import Service from "./Service";
import Font from "./Font";
import PaymentPlan from "./PaymentPlan";
import MetaTag from "./MetaTag";

export default class Event {
	private id: Number | null;
	private title: String | null;
	private slug: String | null;
	private organisation_id: Number | null;
	private logo: String | null;
	private background: String | null;
	private background_type: String | null;
	private color: String | null;
	private favicon: String | null;
	private event_gdpr: boolean;
	private event_gdpr_link: String | null;
	private security_type: String;
	private security_data: String | null;
	private template: Object;
	private zones: Array<TemplateZone>;
	private translation_data: any;
	private show_title: boolean | null;
	private current_state: any;
	private start: string | null;
	private end: string | null;
	private exhibitions: Array<Exhibition>;
	private services: Array<Service> | null;
	private fonts: Array<Font> | null;
	private event_template_settings: any | null;
	private paymentPlans: Array<PaymentPlan> | null;
	private interactivity_block: boolean | null;
	private navigation: any;
	private organisation: any | null;
	private loading_settings: string;
	private metaTag: MetaTag | null;
	private css: string | null;
	private is_demo: boolean | null;
	private oauth_credentials: any | null;


	constructor(id: Number | null = null, title: String | null = "", slug: String | null = "",
		organisation_id: Number | null = null, logo: String | null = null,
		background: String | null = null, background_type: String | null = null,
		color: String | null = null, favicon: String | null = null, event_gdpr: boolean = false,
		event_gdpr_link: String | null = null, security_type: String = "public", security_data: String | null = null,
		template: Object = { path: "base/BaseTemplateHomePage.tsx" },
		zones: Array<TemplateZone> = [], translation_data: any = {}, show_title: boolean = false, current_state: any = null, start: string | null = null, end: string | null = null,
		exhibitions: Array<Exhibition> = [],
		services: Array<Service> = [],
		fonts: Array<Font> = [],
		template_settings: any | null = null,
		paymentPlans: Array<PaymentPlan> | null = null,
		interactivity_block: boolean | null = false,
		navigation: any | null = null,
		organisation: any | null = null,
		loading_settings: string = "{}",
		metaTag: MetaTag | null = null,
		css: string | null = null,
		is_demo: boolean | null = null,
		oauth_credentials: any | null = null,
	) {
		this.id = id;
		this.title = title;
		this.slug = slug;
		this.organisation_id = organisation_id;
		this.logo = logo;
		this.background = background;
		this.background_type = background_type;
		this.color = color;
		this.favicon = favicon;
		this.event_gdpr = event_gdpr;
		this.event_gdpr_link = event_gdpr_link;
		this.security_type = security_type;
		this.security_data = security_data;
		this.template = template;
		this.zones = zones;
		this.translation_data = translation_data;
		this.show_title = show_title
		this.current_state = current_state;
		this.start = start;
		this.end = end;
		this.exhibitions = exhibitions;
		this.services = services;
		this.fonts = fonts;
		this.event_template_settings = template_settings;
		this.paymentPlans = paymentPlans;
		this.interactivity_block = interactivity_block;
		this.navigation = navigation;
		this.organisation = organisation;
		this.loading_settings = loading_settings;
		this.metaTag = metaTag;
		this.css = css;
		this.is_demo = is_demo;
		this.oauth_credentials = oauth_credentials;
	}


	public static fromJSON(json: any): Event {

		var event: Event = new Event();
		Object.assign(event, json);

		var tempZones: Array<TemplateZone> = [];
		if (json.zones) {
			json.zones.forEach((zone: Object) => {
				tempZones.push(TemplateZone.fromJSON(zone))
			});
		}

		const exhibs: Array<Exhibition> = [];
		if (json.exhibitions) {

			json.exhibitions.forEach((exhib: Object) => {
				exhibs.push(Exhibition.fromJSON(exhib))
			})
		}

		const _services: Array<Service> = [];
		if (json.services) {
			json.services.forEach((service: Object) => {
				_services.push(Service.fromJSON(service))
			})
		}
		const _fonts: Array<Font> = [];
		if (json.fonts) {
			json.fonts.forEach((font: Object) => {
				_fonts.push(Font.fromJSON(font))
			})
		}

		if (json.event_template_settings) {
			event.$templateSettings = JSON.parse(json.event_template_settings);
		}

		if (json.payment_plans) {
			const _plans = [];
			for (let p of json.payment_plans) {
				_plans.push(PaymentPlan.fromJSON(p));
			}
			event.$paymentPlans = _plans;
		}

		if (json.meta_tag) {
			const _meta = MetaTag.fromJSON(json.meta_tag);
			event.$metaTag = _meta;
		}

		event.$services = _services;
		event.$exhibitions = exhibs;
		event.$zones = tempZones;
		event.$fonts = _fonts;

		return event;
	}
	public get $isDemo(): boolean | null { return this.is_demo; }
	public set $isDemo(value: boolean | null) { this.is_demo = value; }

	public get $css(): string | null { return this.css; }
	public set $css(value: string | null) { this.css = value; }


	public get $metaTag(): MetaTag | null { return this.metaTag; }
	public set $metaTag(value: MetaTag | null) { this.metaTag = value; }

	public get $zones(): Array<TemplateZone> {
		return this.zones;
	}

	public get $title(): String | null {
		return this.title;
	}

	public get $slug(): String | null {
		return this.slug;
	}
	public set $slug(value: String | null) {
		this.slug = value;
	}


	/**
	 * Getter $id
	 * @return {Number }
	 */
	public get $id(): Number | null {
		return this.id;
	}

	/**
	 * Getter $organisation_id
	 * @return {Number }
	 */
	public get $organisation_id(): Number | null {
		return this.organisation_id;
	}

	public get $navigation() { return this.navigation }
	public set $navigation(value: any) { this.navigation = value; }

	public get $organisation() { return this.organisation; }
	public set $organisation(value: any) { this.organisation = value; }

	/**
	 * Getter $logo
	 * @return {String }
	 */
	public get $logo(): String | null {
		return this.logo;
	}

	/**
	 * Getter $background
	 * @return {String }
	 */
	public get $background(): String | null {
		//Check if an exhibition is active
		const e = this.$active_exhibition;
		if (e) {
			if (e.$layout_settings && e.$layout_settings['background']) {
				return e.$layout_settings['background'];
			}
			else {
				return this.background_type;
			}
		}

		//Check if the current state defines another background 
		try {
			let stateSettings = this.current_state.layout_settings;
			if (stateSettings && stateSettings.length != 0) {
				const settings = JSON.parse(stateSettings);
				return settings['background'];
			}
		} catch (e) { }

		return this.background;
	}

	/**
	 * Getter $background_type
	 * @return {String }
	 */
	public get $background_type(): String | null {
		//Check if an exhibition is active
		const e = this.$active_exhibition;
		if (e) {
			if (e.$layout_settings && e.$layout_settings['background_type']) {
				return e.$layout_settings['backgroundType'];
			}
			else {
				return this.background_type;
			}
		}
		try {
			let stateSettings = this.current_state.layout_settings;
			if (stateSettings && stateSettings.length != 0) {
				const settings = JSON.parse(stateSettings);
				if (settings['background_type'].length > 0)
					return settings['background_type'];
			}
		} catch (e) { }
		return this.background_type;
	}

	/**
	 * Getter $color
	 * @return {String }
	 */
	public get $color(): String | null {
		//Check if an exhibition is active
		const e = this.$active_exhibition;
		if (e) {
			if (e.$layout_settings && e.$layout_settings['accentColor']) {
				return e.$layout_settings['accentColor'];
			}
			else {
				return this.background_type;
			}
		}
		try {
			let stateSettings = this.current_state.layout_settings;
			if (stateSettings && stateSettings.length != 0) {
				const settings = JSON.parse(stateSettings);
				return settings['accent_color'];
			}
		} catch (e) { }
		return this.color;
	}

	/**
	 * Getter $favicon
	 * @return {String }
	 */
	public get $favicon(): String | null {
		return this.favicon;
	}

	/**
	 * Getter $event_gdpr
	 * @return {boolean}
	 */
	public get $event_gdpr(): boolean {
		return this.event_gdpr;
	}

	/**
	 * Getter $event_gdpr_link
	 * @return {String }
	 */
	public get $event_gdpr_link(): String | null {
		return this.event_gdpr_link;
	}

	/**
	 * Getter $security_type
	 * @return {String }
	 */
	public get $security_type(): String {
		return this.security_type;
	}

	public get $template(): Object {
		return this.template;
	}

	/**
	 * Getter $security_data
	 * @return {String}
	 */
	public get $security_data(): String | null {
		return this.security_data;
	}
	public get $languages(): any[] {
		return this.translation_data.languages;
	}
	public get $default_language(): any {
		for (let l of this.translation_data.languages) {
			if (l.is_default) {
				return l;
			}
		}
		return null;
	}
	public get $additional_languages(): any[] {
		const result = [];
		for (let l of this.translation_data.languages) {
			if (!l.is_default) {
				result.push(l);
			}
		}
		return result;
	}
	public get $translation_data(): any {
		return this.translation_data;
	}
	public get $show_title(): boolean | null {
		return this.show_title;
	}
	public get $current_state(): any {
		return this.current_state;
	}
	public get $start(): string | null {
		return this.start;
	}
	public get $end(): string | null {
		return this.end;
	}
	public get $exhibitions(): Array<Exhibition> {
		return this.exhibitions;
	}
	public get $active_exhibition(): Exhibition | null {
		//Check if an exhibition is active 
		try {
			const path = window.location.pathname;
			if (path.includes('exhibition')) {
				const parts = path.split('/');
				const slugIndex = parts.indexOf('exhibition') + 1;
				const slug = parts[slugIndex];

				for (let e of this.$exhibitions) {
					if (e.$slug == slug) {
						return e;
					}
				}
			}
		} catch (e) { }
		return null;
	}
	public get $services(): Array<Service> | null {
		return this.services;
	}
	public get $fonts(): Array<Font> | null {
		return this.fonts;
	}
	public get $templateSettings(): any | null {
		return this.event_template_settings;
	}
	public get $paymentPlans(): Array<PaymentPlan> | null {
		return this.paymentPlans;
	}
	public get $interactivityBlock(): boolean | null { return this.interactivity_block; }

	public get $loading_settings(): string {
		return this.loading_settings;
	}

	/**
	 * Setter $id
	 * @param {Number } value
	 */
	public set $id(value: Number | null) {
		this.id = value;
	}

	/**
	 * Setter $title
	 * @param {String } value
	 */
	public set $title(value: String | null) {
		this.title = value;
	}

	/**
	 * Setter $organisation_id
	 * @param {Number } value
	 */
	public set $organisation_id(value: Number | null) {
		this.organisation_id = value;
	}

	/**
	 * Setter $logo
	 * @param {String } value
	 */
	public set $logo(value: String | null) {
		this.logo = value;
	}

	/**
	 * Setter $background
	 * @param {String } value
	 */
	public set $background(value: String | null) {
		this.background = value;
	}

	/**
	 * Setter $background_type
	 * @param {String } value
	 */
	public set $background_type(value: String | null) {
		this.background_type = value;
	}

	/**
	 * Setter $color
	 * @param {String } value
	 */
	public set $color(value: String | null) {
		this.color = value;
	}

	/**
	 * Setter $favicon
	 * @param {String } value
	 */
	public set $favicon(value: String | null) {
		this.favicon = value;
	}

	/**
	 * Setter $event_gdpr
	 * @param {boolean} value
	 */
	public set $event_gdpr(value: boolean) {
		this.event_gdpr = value;
	}

	/**
	 * Setter $event_gdpr_link
	 * @param {String } value
	 */
	public set $event_gdpr_link(value: String | null) {
		this.event_gdpr_link = value;
	}

	/**
	 * Setter $security_type
	 * @param {String } value
	 */
	public set $security_type(value: String) {
		this.security_type = value;
	}

	/**
	 * Setter $security_data
	 * @param {String} value
	 */
	public set $security_data(value: String | null) {
		this.security_data = value;
	}

	public set $template(value: Object) {
		this.template = value;
	}

	public set $zones(value: Array<TemplateZone>) {
		this.zones = value;
	}
	public set $show_title(value: boolean | null) {
		this.show_title = value;
	}
	public set $current_state(value: any | null) {
		this.current_state = value;
	}
	public set $exhibitions(value: Array<Exhibition>) {
		this.exhibitions = value;
	}

	public set $services(value: Array<Service> | null) {
		this.services = value;
	}

	public hasService(serviceName: string) {
		if (!this.services) return false;
		let result = false;
		for (let service of this.services) {
			if (service.$name == serviceName) {
				result = true;
			}
		}
		return result;
	}

	public set $fonts(value: Array<Font> | null) {
		this.fonts = value;
	}
	public set $templateSettings(value: any | null) { this.event_template_settings = value }
	public set $paymentPlans(value: Array<PaymentPlan> | null) { this.paymentPlans = value }
	public set $loading_settings(value: string) {
		this.loading_settings = value;
	}

	public get $oauthCredentials() { return this.oauth_credentials; }
}