import React, { useReducer, createContext } from 'react';
import { useReducerAsync } from 'use-reducer-async';
import UserReducer from '../reducers/UserReducer';
import User from '../Models/User';

function UserContext(reducer, initialState) {
	const Context = createContext(initialState);

	const Provider = ({ children }) => {
		const [userState, userDispatch] = useReducer(reducer, initialState);

		return (
			<Context.Provider value={{ userState, userDispatch }}>
				{children}
			</Context.Provider>
		);
	};

	return { Context, Provider };
}

export const { Context, Provider } = UserContext(UserReducer, {
	user: new User(),
});
