import React, { FC, useState, useEffect, useContext } from 'react';
import { Context as EventContext } from '../../context/EventContext';
import { Context as UserContext } from '../../context/UserContext';
import { withSocket } from '../../HOC/Socket/withSocket';
interface Props {

}
const UserBlocker: FC<Props> = (props) => {
	const { state, dispatch } = useContext(EventContext);
	const { userState, userDispatch } = useContext(UserContext);

	const listenForUpdates = () => {
		props.subscribe("user/" + userState.user.$id + "/block", () => {
			window.location.reload()
		})
		props.subscribe("user/" + userState.user.$id + "/ban", () => {
			localStorage.removeItem('_myAraToken');
			window.location.reload()
		})
	}

	useEffect(() => {
		listenForUpdates();
	}, [])

	return (
		<></>
	)
}
export default withSocket(UserBlocker);