import React, { FC, useState, useEffect, useContext, lazy, Suspense } from 'react';
import { Router as ReactRouter, useParams } from 'react-router';
import Event from '../Models/Event';
import PreviewService from '../Services/PreviewService';
import { Context as EventContext } from '../context/EventContext'
import { Context as UserContext } from '../context/UserContext'
import { Context as LanguageContext } from '../context/LanguageContext'
import User from '../Models/User';
import AuthService from '../Services/AuthService';
import _uniqueId from 'lodash/uniqueId';
import { BrowserRouter } from 'react-router-dom';
import InnerHTML from 'dangerously-set-html-content';
import Heartbeat from '../components/Heartbeat';
import UserBlocker from '../components/UserBlocker';
import Router from '../Router';
import Notifications from '../HOC/Notifications';
import ComponentRefresher from '../components/ComponentRefresher';
import { Header } from '../layout-components';
import { Alert } from 'reactstrap';

interface Props {

}

const importView = (c) =>
	lazy(() =>
		import(`../Templates/${c}`).catch((error) => {
			console.error(`COULD NOT IMPORT ${c}`);
			console.error(error);
		})
	);

const CompList = ({ comps }) =>
	Object.values(comps).map((Comp) => {
		return <Comp key={_uniqueId('Comp-')} />;
	});

const AraPreviewRouter: FC<Props> = (props) => {
	const [previewSlug, setPreviewSlug] = useState("");
	const { state, dispatch } = useContext(EventContext);
	const { userState, userDispatch } = useContext(UserContext);
	const { languageState, languageDispatch } = useContext(LanguageContext);
	const [comps, setComps] = useState({});
	const [appMargin, setAppMargin] = useState('0px');

	useEffect(() => {
		init();
	}, [])

	const fetchUser = async (userType: string | undefined = undefined) => {
		const user = User.fromJSON(await AuthService.getMe());
		if (userType) user.$isWatchingAs = userType;
		userDispatch({ type: 'SET_USER', user: user });

	}

	const init = async () => {
		if (state.event && state.event.$id) return;
		const _previewSlug = window.location.pathname.substring(1);
		setPreviewSlug(_previewSlug);

		try {
			var previewData = await PreviewService.decryptPreviewInfo(window.location.origin, _previewSlug)
			const event = Event.fromJSON(previewData['event'])
			const token = previewData['auth']
			localStorage.setItem('_myAraToken', token);
			dispatch({ type: 'SET_EVENT', event: event, errorCallback: errorCallback, callback: setupEvent })
			fetchUser(previewData['participant_type']);

		} catch (err) {
			console.log(err)
		}
	}

	const errorCallback = (errorCode) => {
		console.error(errorCode)
	};

	const setupEvent = (tempPath, faviconHref, title, translationData) => {
		_loadTemplate(tempPath);
		document.title = title;
		if (faviconHref) {
			_setFavIcon(faviconHref);
		}

		setupLanguages(translationData);
		//setupDefaultLanguage(defaultLanguage);
	};

	const _loadTemplate = (tempPath) => {
		if (comps[tempPath]) return;

		const Comp = importView(tempPath);
		setComps((c) => ({ ...c, [tempPath]: Comp }));
	};

	const _setFavIcon = (faviconHref) => {
		const favicon = document.getElementById('favicon');
		favicon.href = faviconHref;
	};

	const setupLanguages = async (translationData) => {
		languageDispatch({
			type: 'SET_TRANSLATION_DATA',
			data: translationData,
			eventId: state.event.id,
		});

	}
	const renderScripts = () => {
		return (
			<>
				{state.event.$fonts && state.event.$fonts.length > 0 ? (
					<style>
						{state.event.$fonts.map((font: Font, index) => {
							const fontDefinition = `
								@font-face {
									font-family: "${font.$font_family}";
									src: url("${font.$url}") format("${font.$file_extension}");
									font-display: swap;
									font-style: ${font.$font_style};
									font-weight: ${font.$font_weight};
								}
							`;

							const subjects = `
								${font.$applied_to?.join(', ')} {
									font-family: "${font.$font_family}";
									font-style: ${font.$font_style};
									font-weight: ${font.$font_weight};
								}
							`;


							return fontDefinition + subjects;


						})}
					</style>

				) : ''}
				{state.event.eventPageScripts ?
					<InnerHTML html={state.event.eventPageScripts} />
					: ""}
				<InnerHTML html={'<style>' + state.event.css + '</style>'} />
			</>
		);
	};

	const Content = () => {
		return (
			<>

				{state.event.$background_type == 'image' ? (
					<div
						className="event-background"
						style={{
							backgroundImage: `url(${state.event.$background})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundPosition: 'center',
						}}
					>
						<Body />
					</div>
				) : ''}
				{state.event.$background_type == 'pattern' ? (
					<div
						className="event-background"
						style={{
							backgroundImage: `url(${state.event.$background})`,
							backgroundRepeat: 'repeat',
						}}
					>
						<Body />
					</div>
				) : ''}
				{state.event.$background_type == 'color' ? (
					<div
						className="event-background"
						style={{
							backgroundColor: state.event.$background,
						}}
					>
						<Body />
					</div>
				) : ''}
				<ComponentRefresher
					refreshTopic={"/event/" + state.event.$id}
				/>

			</>
		)
	}

	const onSidebarToggle = (isOpen, sidebarWidth, isMobile) => {

		if (isOpen && !isMobile) {

			setAppMargin(sidebarWidth + 'px');
		} else {
			setAppMargin('0px')
		}
	}

	const Body = () => {
		return (
			<div className="app-main" style={{ marginRight: appMargin, transition: "all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1)" }}>

				<Header
					user={userState.user}
					color={state.event.$color}
					logo={state.event.$logo}
					title={state.event.$title}
					showTitle={state.event.$show_title}
					onSidebarToggle={onSidebarToggle}
				>
				</Header>
				<div className={"app-content"}>
					<div className="app-content--inner">

						<div className="app-content--inner__wrapper">
							<Router />
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			<Alert className="mb-0 rounded-0 text-center preview-alert" color="warning" style={{ height: "3rem" }}>
				🚨 This is the preview page, user's input is disabled 🚨 </Alert>
			{state.event && state.event.$id && userState.user ? (

				<Suspense fallback={<Router />}>
					{renderScripts()}
					<Heartbeat
						heartbeatInterval={
							Math.round((60 + Math.random() * 60) * 1000)
						}
					/>
					<UserBlocker />
					<Content />
				</Suspense>
			) : <Router />}
			<Notifications />
		</>
	)
}
export default AraPreviewRouter;