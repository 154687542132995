import React, { FC, useState, useContext, useEffect } from 'react'
import AuthService from '../../Services/AuthService'
import { Context as EventContext } from '../../context/EventContext'
import { Context as LanguageContext } from '../../context/LanguageContext'
import LayoutRenderer from '../LayoutRenderer'
import EventBlock from '../../Models/EventBlock'
import {
	Card,
	Nav,
	NavItem,
	Button,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	ListGroup,
	ListGroupItem,
} from 'reactstrap'
import clsx from "clsx"
import * as FontIcon from 'react-icons/fa'
import HeaderMenu from '../HeaderMenu'
import Zone02Sidebar from '../../Templates/base/zones/zone02-Sidebar'
import { default as MobileZone02 } from '../../Templates/full-page/zones/zone02-Sidebar'
import HeaderUserbox from '../HeaderUserbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";
import HeaderLanguageSelector from '../HeaderLanguageSelector'
import HeaderHybridSelector from '../HeaderHybridSelector'
import AnalyticsService from '../../Services/AnalyticsService'
import HeaderTimezoneSelector from '../HeaderTimezoneSelector'
import ColorUtils from '../../utils/ColorUtils'
import { useNavigate } from 'react-router'
import NavigateUtils from '../../utils/NavigateUtils'


interface Props {
	headerBgTransparent;
	sidebarToggleMobile;
	toggleSidebarMobile;
	showTitle;
	logo;
	color;
	title;
	user;
	onSidebarToggle;
	isMobile;
	showBackButton;
}
const HeaderNavigation: FC<Props> = (props) => {
	const { state, dispatch } = useContext(EventContext);
	const { languageState, languageDispatch } = useContext(LanguageContext);

	const [navLayout, setNavLayout] = useState<any>();
	const [blocks, setBlocks] = useState<EventBlock[]>([])

	const [menu, setMenu] = useState<Array<any>>();

	const [activeTab, setActiveTab] = useState(0)
	const { t, i18n } = useTranslation('header');

	const [desktopTemplate, setDesktopTemplate] = useState("default");
	const [mobileTemplate, setMobileTemplate] = useState("default");

	const [disabled, setDisabled] = useState(false);
	const reactNavigate = useNavigate();

	const init = async () => {
		if (!state.event.$id) return;

		try {

			if (state.event.$navigation.layout) {
				const layout = JSON.parse(state.event.$navigation.layout.settings);
				setNavLayout(layout);
			}

			const _blocks: Array<EventBlock> = [];
			for (let b of state.event.$navigation.layout.blocks) {
				_blocks.push(EventBlock.fromJSON(b));
			}
			setBlocks(_blocks);
		} catch (e) {

		}

		try {

			if (state.event.$navigation.menu) {
				setMenu(state.event.$navigation.menu);
				checkActive(state.event.$navigation.menu);
			}
		} catch (e) {

		}

		for (let zone of state.event.$zones) {
			if (zone.$name == 'Navigation bar zone') {
				if (zone.$zone_settings) {
					for (let setting of zone.$zone_settings) {
						if (setting.slug == 'show_zone' && !setting.value) {
							setDisabled(true);
						}
						if (setting.slug == 'desktop_template' && setting.value) {
							setDesktopTemplate(setting.value);
						}
						if (setting.slug == 'mobile_template' && setting.value) {
							setMobileTemplate(setting.value);
						}
					}
				}
			}
		}
	}

	useEffect(() => {
		init();
	}, [])

	const checkActive = (items) => {
		let index = 0;
		setActiveTab(parseInt(localStorage.getItem('activeNavTab')));
		const currUrl = window.location.protocol + "//" + window.location.host + window.location.pathname
		for (let item of items) {
			if (item.destination.path != '/') {
				if (window.location.pathname.includes(item.destination.path)) {
					setActiveTab(index);
					localStorage.setItem('activeNavTab', '' + index);
				}
			} else {
				if (currUrl == state.event.base_url) {
					setActiveTab(index)
					localStorage.setItem('activeNavTab', '' + index);
				}
			}
			index++;
		}
	}

	const SplittedNavigationBar1 = (blockProps) => {
		const showLogo = blockProps.showLogo;
		return (
			<div
				className={clsx('app-header', {
				})}
			>
				<div className="app-header--pane event-header-logo">
					<button
						className={clsx(
							'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
							{ 'is-active': props.sidebarToggleMobile },
							'd-none'
						)}
						onClick={props.toggleSidebarMobile}
					>
						<span className="hamburger-box">
							<span className="hamburger-inner" />
						</span>
					</button>
					{showLogo ? (
						<img
							src={props.logo}
							className="navigation-logo"
							style={{
								maxHeight: 45 + 'px',
							}}
							alt="logo event"
							onClick={(e) => {
								NavigateUtils.navigate(
									reactNavigate,
									state.event,
									'/'
								);
							}}
						/>
					) : ""}

					{!menu || menu.length == 0 ? (
						<BackButton />
					) : ""}


					{/* <HeaderLanguageSelector hideLabels={isMobile}></HeaderLanguageSelector> */}

					<HeaderMenu />
				</div>
				{props.showTitle ? (
					<div className="app-header--pane event-header-title-container">
						<h1 className="event-header-title mx-0 my-3 font-weight-bold" style={{ color: props.color }}>{props.title}</h1>
					</div>
				) : ''}

				<div className="app-header--pane event-header-buttons">
					{state.event && state.event.$navigation && state.event.$navigation.sidebar ? (
						<Zone02Sidebar layout={state.event.$navigation.sidebar} color={props.color} onSidebarToggle={props.onSidebarToggle} />
					) : ""}
					<HeaderTimezoneSelector />
					<HeaderUserbox user={props.user} disableTZSelector={true} />
				</div>
			</div>
		)
	}

	const SplittedNavigationBar2 = (blockProps) => {
		const showLogo = blockProps.showLogo;
		return (
			<div
				className={'app-header'}
			>
				{showLogo ? (

					<img
						src={props.logo}
						className="navigation-logo"
						style={{
							maxHeight: 45 + 'px',
						}}
						alt="logo event"
						onClick={(e) => {
							NavigateUtils.navigate(
								reactNavigate,
								state.event,
								'/'
							);
						}}
					/>
				) : (
					<div className="app-header--pane event-header-buttons"></div>

				)}
				{!menu || menu.length == 0 ? (
					<BackButton />
				) : ""}
				{menu && menu.length > 0 ? (
					<div className="app-header--pane event-header-buttons">
						<div className="p-md-4 p-2 mb-2 navigation-menu">
							<div className="event-nav-tabs">
								<Menu />
							</div>
						</div>
					</div>
				) : ""}
				<div className="app-header--pane event-header-buttons"></div>
			</div>
		)

	}

	const BackButton = () => {
		return (
			<>
				{props.showBackButton ? (

					<Button
						style={{
							backgroundColor: props.color + "40",
							borderColor: props.color,
							color: props.color
						}}
						className="ml-3"
						onClick={() => {
							NavigateUtils.navigate(
								reactNavigate,
								state.event,
								'/'
							);
						}}
					>
						<FontAwesomeIcon icon="chevron-left" className="mr-2" />
						{t('back-to-event')}
					</Button>
				) : ""}
			</>
		)
	}

	const Navigation = () => {
		return (
			<div
				className={clsx('app-header', {
				})}
			>
				<div className="app-header--pane event-header-logo">
					<button
						className={clsx(
							'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
							{ 'is-active': props.sidebarToggleMobile },
							'd-none'
						)}
						onClick={props.toggleSidebarMobile}
					>
						<span className="hamburger-box">
							<span className="hamburger-inner" />
						</span>
					</button>

					<img
						src={props.logo}
						className="navigation-logo"
						style={{
							maxHeight: 45 + 'px',
						}}
						alt="logo event"
						onClick={(e) => {
							NavigateUtils.navigate(
								reactNavigate,
								state.event,
								'/'
							);
						}}
					/>

					{!menu || menu.length == 0 ? (
						<BackButton />
					) : ""}

					{/* <HeaderLanguageSelector hideLabels={isMobile}></HeaderLanguageSelector> */}

					<HeaderMenu />
				</div>
				{props.showTitle ? (
					<div className="app-header--pane event-header-title-container">
						<h1 className="event-header-title mx-0 my-3 font-weight-bold" style={{ color: props.color }}>{props.title}</h1>
					</div>
				) : ''}
				{menu && menu.length > 0 ? (
					<div className="app-header--pane event-header-buttons">
						<div className="p-md-4 p-2 mb-2 navigation-menu">
							<div className="event-nav-tabs">
								<Menu />
							</div>
						</div>
					</div>
				) : ""}
				<div className="app-header--pane event-header-buttons">
					{state.event && state.event.$navigation && state.event.$navigation.sidebar ? (
						<Zone02Sidebar layout={state.event.$navigation.sidebar} color={props.color} onSidebarToggle={props.onSidebarToggle} />
					) : ""}
					<HeaderUserbox user={props.user} />
				</div>
			</div>
		)
	}

	const Menu = () => {
		return (

			<Nav className="nav-line">
				{menu.map((tab, tabIndex) => {
					const inHybrid = localStorage.getItem('is_hybrid') == "on";

					return (
						<NavItem key={tab.key}>
							<NavLink
								onClick={(e) => {
									setActiveTab(tabIndex)

									localStorage.setItem('activeNavTab', '' + tabIndex);

									if (tab.destination.type == 'Custom URL') {
										if (tab.destination.open_blank) {
											window.open(tab.destination.url, '_blank');
										} else {
											window.location = tab.destination.url;
										}
									} else {

										try {
											NavigateUtils.navigate(
												reactNavigate,
												state.event,
												tab.destination.path
											);
										} catch (e) {
											window.location = state.event.base_url + tab.destination.path;
										}
									}
									//history.push(tab.destination.path);
								}}
								// href={'#' + tab.slug}
								className={clsx("p-0 mx-3 pb-2", {
									"active": activeTab == tabIndex,
								})}>
								<span className="font-weight-bold text-uppercase font-size-sm">
									{tab.icon_type == 'image' ? (
										<img src={tab.icon} className="d-20 mr-2" style={{ objectFit: 'contain' }} />
									) : (
										<>
											{tab.icon ? (<>
												{React.createElement(FontIcon[tab.icon], {
													className: "mr-2"
												})}
											</>) : ''}
										</>
									)}
									{tab.title}
								</span>
							</NavLink>
						</NavItem>
					)
				})}
			</Nav>
		)
	}

	const MobileMenu = () => {
		return (
			<Nav className="nav-line">
				{menu.map((tab, tabIndex) => {
					const inHybrid = localStorage.getItem('is_hybrid') == "on";

					return (
						<NavItem
							id={"nav-item-" + tab.slug}
							key={tab.key}
							className="w-100"
							onClick={(e) => {
								setActiveTab(tabIndex)
							}}
						>
							<NavLink
								onClick={(e) => {
									localStorage.setItem('activeNavTab', '' + tabIndex);

									if (tab.destination.type == 'Custom URL') {
										if (tab.destination.open_blank) {
											window.open(tab.destination.url, '_blank');
										} else {
											window.location = tab.destination.url;
										}
									} else {

										try {
											NavigateUtils.navigate(
												reactNavigate,
												state.event,
												tab.destination.path
											);
										} catch (e) {
											window.location = state.event.base_url + tab.destination.path;
										}
									}
									//history.push(tab.destination.path);
								}}
								// href={'#' + tab.slug}
								className={clsx("p-0 mx-3 mb-2", {
									"active pb-2": activeTab == tabIndex,
								})}>
								<span className="font-weight-bold text-uppercase font-size-sm">
									{tab.icon_type == 'image' ? (
										<img src={tab.icon} className="d-20 mr-2" style={{ objectFit: 'contain' }} />
									) : (
										<>
											{tab.icon ? (<>
												{React.createElement(FontIcon[tab.icon], {
													className: "mr-2"
												})}
											</>) : ''}
										</>
									)}
									{tab.title}
								</span>
							</NavLink>
						</NavItem>
					)

				})}

			</Nav>
		)
	}

	const blockIcon = (block) => {
		const arr = block.$icon.split('-');
		const type = arr[0];
		let icon = '';
		for (let i = 1; i < arr.length; i++) {
			icon += arr[i];
			if (i < arr.length - 1) {
				icon += '-';
			}
		}
		return [type, icon];
	}

	const MobileSidebar = () => {
		return (
			<>
				{state.event.$navigation && state.event.$navigation.sidebar && state.event.$navigation.sidebar.blocks && state.event.$navigation.sidebar.blocks.length > 0 ? (
					<>
						<div className='divider' />
						<div className="d-flex py-3  flex-wrap" style={{ rowGap: "10px" }}>
							<>
								<div className="divider" />
								{state.event.$navigation.sidebar.blocks.map((blockJson, blockIndex) => {
									const block = EventBlock.fromJSON(blockJson);
									return (

										<Button
											style={{ backgroundColor: ColorUtils.hexToRgba(props.color, 0.15), color: props.color }}
											id={"sidebar-btn-" + blockIndex}
											className="sidebar-btn font-size-lg p-0 shadow-none border-0 text-center rounded position-relative mx-2 drawer-btn d-50 "
											onClick={(e) => {
												const ev = new CustomEvent('toggle-sidebar', {
													detail: {
														block_id: block.$id
													}
												})
												window.dispatchEvent(ev);
											}}
											key={"sidebar-button-" + blockIndex}
										>
											<div className="m-0" style={{ lineHeight: '0.65rem' }}>
												<FontAwesomeIcon icon={blockIcon(block)} size="lg" />
											</div>
											<div className="m-0 p-0 pt-1" style={{ fontSize: '0.75rem', lineHeight: '0.65rem', fontWeight: 'bold' }}>{block.$settings.blockTitle}</div>
										</Button>

									)
								})}
							</>
						</div>
					</>
				) : ""}
			</>
		)
	}
	const logout = () => {
		AnalyticsService.post(
			{ event: 'LOGGED OUT' },
			window.location.pathname,
			state.event.id,
			props.user.id,
			'auth'
		);

		AuthService.logout();
		window.location.reload();
	};

	const MobileProfileMenu = () => {
		return (
			<>
				<div className="divider mb-2" />
				<Nav pills
					className="nav-line d-flex justify-content-center">
					<NavItem className="w-100">
						<NavLink onClick={() => {
							NavigateUtils.navigate(
								reactNavigate,
								state.event,
								'profile'
							);
						}}
							className="font-weight-bold text-uppercase font-size-sm p-0 mx-3 mb-2">
							<FontAwesomeIcon
								icon={['fa', 'user']}
								className="opacity-5 mr-2"
							/>
							{t('profile')} {' '}
						</NavLink>
					</NavItem>
					<NavItem className="w-100">
						<NavLink onClick={(e) => logout()}
							className="font-weight-bold text-uppercase font-size-sm p-0 mx-3 mb-2">
							<FontAwesomeIcon
								icon={['fas', 'sign-out-alt']}
								className="opacity-5 mr-2"
							/>
							{t('logout')}
						</NavLink>
					</NavItem>
					{state.event.hasService('hybrid') ? (
						<NavItem>
							<NavLink>
								<HeaderHybridSelector></HeaderHybridSelector>
							</NavLink>
						</NavItem>
					) : ''}
					<NavItem>
						<NavLink>
							<HeaderTimezoneSelector />
						</NavLink>
					</NavItem>
					{languageState.possibleLanguages.length > 1 && languageState.currentLanguage ? (
						<>
							<NavItem>
								<NavLink className="p-2">
									<HeaderLanguageSelector
										eventSlug={state.event.$slug}
										organisationSlug={state.event.$organisation.slug}
										eventId={state.event.$id}
										relative={true}
										hideLabels={false}
										eventColor={state.event.$color}
									/>
								</NavLink>
							</NavItem>
						</>
					) : ""}
				</Nav>
			</>
		)
	}

	const SplittedMobileNavigation = () => {
		return (
			<>
				<div
					className={clsx('app-header', {
					})}
				>
					<div className="app-header--pane event-header-logo">
						<button
							className={clsx(
								'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
								{ 'is-active': props.sidebarToggleMobile },
								'd-none'
							)}
							onClick={props.toggleSidebarMobile}
						>
							<span className="hamburger-box">
								<span className="hamburger-inner" />
							</span>
						</button>

						<img
							src={props.logo}
							className="navigation-logo"
							style={{
								maxHeight: 45 + 'px',
							}}
							alt="logo event"
							onClick={(e) => {
								NavigateUtils.navigate(
									reactNavigate,
									state.event,
									'/'
								);
							}}
						/>

						{!menu || menu.length == 0 ? (
							<BackButton />
						) : ""}

						{/* <HeaderLanguageSelector hideLabels={isMobile}></HeaderLanguageSelector> */}

						<HeaderMenu />
					</div>
					{props.showTitle ? (
						<div className="app-header--pane event-header-title-container">
							<h1 className="event-header-title mx-0 my-3 font-weight-bold" style={{ color: props.color }}>{props.title}</h1>
						</div>
					) : ''}
					<div className="app-header--pane event-header-buttons">
						{state.event && state.event.$navigation && state.event.$navigation.sidebar ? (
							<Zone02Sidebar layout={state.event.$navigation.sidebar} color={props.color} onSidebarToggle={props.onSidebarToggle} />
						) : ""}
						<UncontrolledDropdown>
							<DropdownToggle
								color="link"
								className="p-0 text-left d-flex btn-transition-none align-items-center"
							>
								<div className="d-block pl-2">
									<FontAwesomeIcon
										icon="bars"
										color={state.event.$color}
										className="opacity-5"
									/>

								</div>
							</DropdownToggle>
							<DropdownMenu
								right
								className="dropdown-menu-lg overflow-hidden p-0"
							>
								<ListGroup flush className="text-left bg-transparent">
									<ListGroupItem className="rounded-top">
										{menu && menu.length > 0 ? (
											<div className="event-nav-tabs">
												<MobileMenu />
											</div>
										) : ""}
									</ListGroupItem>
								</ListGroup>
							</DropdownMenu>
						</UncontrolledDropdown>
						<HeaderUserbox user={props.user} />
					</div>
				</div>
			</>
		)
	}

	const StrippedMobileNavigation = () => {
		return (
			<>
				<div
					className={clsx('app-header', {
					})}
				>
					<div className="app-header--pane event-header-logo">
						<button
							className={clsx(
								'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
								{ 'is-active': props.sidebarToggleMobile },
								'd-none'
							)}
							onClick={props.toggleSidebarMobile}
						>
							<span className="hamburger-box">
								<span className="hamburger-inner" />
							</span>
						</button>



						{/* <HeaderLanguageSelector hideLabels={isMobile}></HeaderLanguageSelector> */}

						<HeaderMenu />
					</div>
					{props.showTitle ? (
						<div className="app-header--pane event-header-title-container">
							<h1 className="event-header-title mx-0 my-3 font-weight-bold" style={{ color: props.color }}>{props.title}</h1>
						</div>
					) : ''}
					<div className="app-header--pane event-header-buttons">

						<UncontrolledDropdown>
							<DropdownToggle
								color="link"
								className="p-0 text-left d-flex btn-transition-none align-items-center"
							>
								<div className="d-block pl-2">
									<FontAwesomeIcon
										icon="bars"
										color={state.event.$color}
										className="opacity-5"
									/>

								</div>
							</DropdownToggle>
							<DropdownMenu
								right
								className="dropdown-menu-lg overflow-hidden p-0"
							>
								<ListGroup flush className="text-left bg-transparent">
									<ListGroupItem className="rounded-top">
										{menu && menu.length > 0 ? (
											<div className="event-nav-tabs">
												<MobileMenu />
											</div>
										) : ""}
									</ListGroupItem>
								</ListGroup>
							</DropdownMenu>
						</UncontrolledDropdown>
					</div>
				</div>
			</>
		)
	}

	const MobileNavigation = () => {
		return (
			<div
				className={clsx('app-header', {
				})}
			>
				<div className="app-header--pane event-header-logo">
					<button
						className={clsx(
							'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
							{ 'is-active': props.sidebarToggleMobile },
							'd-none'
						)}
						onClick={props.toggleSidebarMobile}
					>
						<span className="hamburger-box">
							<span className="hamburger-inner" />
						</span>
					</button>

					<img
						src={props.logo}
						className="navigation-logo"
						style={{
							maxHeight: 45 + 'px',
						}}
						alt="logo event"
						onClick={(e) => {
							NavigateUtils.navigate(
								reactNavigate,
								state.event,
								'/'
							);
						}}
					/>

					{!menu || menu.length == 0 ? (
						<BackButton />
					) : ""}

					{/* <HeaderLanguageSelector hideLabels={isMobile}></HeaderLanguageSelector> */}

					<HeaderMenu />
				</div>
				{props.showTitle ? (
					<div className="app-header--pane event-header-title-container">
						<h1 className="event-header-title mx-0 my-3 font-weight-bold" style={{ color: props.color }}>{props.title}</h1>
					</div>
				) : ''}

				<div className="app-header--pane event-mobile-bars">
					<UncontrolledDropdown>
						<DropdownToggle
							color="link"
							className="p-0 text-left d-flex btn-transition-none align-items-center"
						>
							<div className="d-block pl-2">
								<FontAwesomeIcon
									icon="bars"
									color={state.event.$color}
									className="opacity-5"
								/>

							</div>
						</DropdownToggle>
						<DropdownMenu
							right
							className="dropdown-menu-lg overflow-hidden p-0"
						>
							<ListGroup flush className="text-left bg-transparent">
								<ListGroupItem className="rounded-top">
									{menu && menu.length > 0 ? (
										<div className="event-nav-tabs">
											<MobileMenu />
										</div>
									) : ""}
									<MobileSidebar />
									<MobileProfileMenu />
								</ListGroupItem>
							</ListGroup>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
				<MobileZone02 color={state.event.$color} onSidebarToggle={props.onSidebarToggle} hideBubble={true} />
			</div>
		)
	}

	const renderNavLayout = () => {
		return (
			<>
				{navLayout && blocks.length > 0 ? (
					<LayoutRenderer
						layout={navLayout}
						blocks={blocks}
					/>
				) : ""}
			</>
		)
	}

	return (
		<>
			<style>{`
			.event-nav-tabs .nav.nav-line .nav-item .nav-link.active {
				color: ${props.color ? props.color : state.event.$color};
				border-bottom: 3px solid ` + state.event.$color + `;
			}
			.navigation-logo:hover {
				cursor: pointer;
			}
		`}</style>
			{!disabled ? (
				<div>

					{props.isMobile ? (
						<>
							{mobileTemplate == 'default' ? (
								<>
									{renderNavLayout()}
									<MobileNavigation />
								</>
							) : ""}
							{mobileTemplate == 'splitted_one' ? (
								<>
									{renderNavLayout()}
									<SplittedMobileNavigation />
								</>
							) : ""}
							{mobileTemplate == 'splitted_two' ? (
								<>
									<SplittedNavigationBar1 showLogo={true} />
									{renderNavLayout()}
									<StrippedMobileNavigation />
								</>
							) : ""}
						</>
					) : (
						<>
							{desktopTemplate == 'default' ? (
								<>
									{renderNavLayout()}
									<Navigation />
								</>
							) : ""}
							{desktopTemplate == 'splitted_one' ? (
								<>
									{renderNavLayout()}
									<SplittedNavigationBar1 showLogo={false} />
									<SplittedNavigationBar2 showLogo={true} />
								</>
							) : ""}
							{desktopTemplate == 'splitted_two' ? (
								<>
									<SplittedNavigationBar1 showLogo={true} />
									{renderNavLayout()}
									<SplittedNavigationBar2 showLogo={false} />
								</>
							) : ""}
						</>
					)}



				</div>
			) : ""}
		</>
	)
}

export default HeaderNavigation;