import axios from 'axios';
import Language from '../Models/Language';
import AuthService from './AuthService';

const LanguageService = {
	getAll: async function (): Promise<Array<Language>> {
		const response = await AuthService.get('translation/language/all');
		var languages: Array<Language> = [];
		const languageResponseData = response;

		for (var i: number = 0; i < languageResponseData.length; i++) {
			const lg: Language = Language.fromJSON(languageResponseData[i]);
			languages.push(lg);
		}

		return languages;
	},
	getById: async function (id: number): Promise<Language> {
		const response = await AuthService.get('translation/language/' + id);

		return Language.fromJSON(response);
	},
	downloadTranslationFromAws: async function (url: string) {
		const response = await axios.get(url);
		return response.data;
	},

};

export default LanguageService;
