
import React, { useState, useEffect, lazy, useContext, FC, Suspense, useRef } from 'react';
import { Button, Col, Badge, UncontrolledTooltip } from 'reactstrap'
import _uniqueId from 'lodash/uniqueId';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PerfectScrollbar from "react-perfect-scrollbar"
import { withSocket } from '../../../HOC/Socket/withSocket'
import { Context as UserContext } from '../../../context/UserContext';
import EventBlock from '../../../Models/EventBlock'
import clsx from "clsx"
import { useTranslation } from "react-i18next";
import { withSnackbar } from '../../../HOC/Snackbar/withSnackbar';
import ColorUtils from '../../../utils/ColorUtils';

function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue(value => value + 1); // update the state to force render
}
interface Props {
	color: string;
	onSidebarToggle?: any;
	layout: any;
}

const Zone02Sidebar: FC<Props> = (props) => {
	const { userState, userDispatch } = useContext(UserContext)
	const [headerDrawerToggle, setHeaderDrawerToggle] = useState(false)
	const headerDrawerToggleRef = useRef(headerDrawerToggle);
	const [sidebarWidth, setSidebarWidth] = useState(450)
	const [blocks, setBlocks] = useState([]);
	const [sidebarAnchor, setSidebarAnchor] = useState("0")
	const forceUpdate = useForceUpdate();
	const [buttons, setButtons] = useState<Array<EventBlock>>([])
	const buttonsRef = useRef(buttons)
	const [activeBlock, setActiveBlock] = useState(0);
	const activeBlockRef = useRef(activeBlock);
	const [notifications, setNotifications] = useState({});
	const listenersSetRef = useRef(false);

	const forceHideRef = useRef(false);

	const { t } = useTranslation('header');

	const timer = useRef(0);

	const fetchLayout = async () => {
		if (!props.layout) return;

		const arr = [];
		for (let block of props.layout.blocks) {
			const b = EventBlock.fromJSON(block);
			arr.push(b);
		}
		setButtons(arr);
		buttonsRef.current = arr;
		const state = arr.map((x, index) => (renderBlock(x)));
		setBlocks(state)

	}

	const toggleDrawer = () => {
		if (window.innerWidth <= 1099.98) setSidebarWidth(window.innerWidth)

		//Prevent flashing blocks
		if (headerDrawerToggleRef.current) {
			forceHideRef.current = true;
			timer.current = window.setTimeout(() => {
				forceHideRef.current = false;
				forceUpdate();
			}, 1000);
		}

		if (props.onSidebarToggle) {
			props.onSidebarToggle(!headerDrawerToggleRef.current, sidebarWidth);
			timer.current = window.setTimeout(() => {
				setHeaderDrawerToggle(!headerDrawerToggleRef.current)
				headerDrawerToggleRef.current = !headerDrawerToggleRef.current;
				forceUpdate();
			}, 100);
		} else {
			setHeaderDrawerToggle(!headerDrawerToggleRef.current)
			headerDrawerToggleRef.current = !headerDrawerToggleRef.current;
			forceUpdate();
		}
	}

	const importBlock = (c) =>
		lazy(() =>
			import(`../../../blocks/${c}`).catch((error) => {
				console.error(`COULD NOT IMPORT Block ${c}`);
				console.error(error);
			})
		);

	function handleWindowSizeChange() {
		if (window.innerWidth <= 1099.98) {
			setSidebarWidth(window.innerWidth);
			setSidebarAnchor("0")
		} else {
			setSidebarWidth(450);
			setSidebarAnchor("0")
		}

	}

	const addEventListeners = () => {
		if (listenersSetRef.current) return;
		window.addEventListener('resize', handleWindowSizeChange);
		window.addEventListener('toggle-sidebar', handleIncomingTrigger);
		listenersSetRef.current = true;
	}

	useEffect(() => {
		fetchLayout();

		handleWindowSizeChange();
		addEventListeners();

		listenForNotifications();

		return (() => clearTimeout(timer.current));
	}, []);

	const handleIncomingTrigger = (e) => {
		for (let i = 0; i < buttonsRef.current.length; i++) {
			const block = buttonsRef.current[i];
			if (block.id == e.detail.block_id) {
				activeBlockRef.current = i;
				setActiveBlock(i)
				break;
			}
		}
		toggleDrawer()
		forceUpdate();
	}

	const listenForNotifications = () => {
		//props.connectToBroker();
		if (userState.user && userState.user.$receive_notifications) {

			let blockIndex = 0;
			for (const block of buttonsRef.current) {
				notifications[block.$id] = false;
				if (block.$notification_topic) {
					props.subscribe(block.$notification_topic, showToast.bind(null, block.$icon, block.$title), true)
					props.subscribe(block.$notification_topic, handleNotification.bind(null, block, blockIndex))
				}
				blockIndex++;
			}
		}
		setNotifications(notifications);
	}

	const handleNotification = (block, blockIndex, msg) => {
		msg = JSON.parse(msg.message);
		if (msg.show) {
			notifications[block.$id] = true;
			setNotifications(notifications);
		}
		forceUpdate();
	}

	const showToast = (icon, title, msg) => {
		if (!userState.user || !userState.user.$receive_notifications) return;

		msg = JSON.parse(msg.message);
		icon = icon.replace("fas-", "")

		if (msg.show_msg) {
			props.openSnackbar(icon, title, msg.msg);
		}
	}

	const blockIcon = (block) => {
		const arr = block.$icon.split('-');
		const type = arr[0];
		let icon = '';
		for (let i = 1; i < arr.length; i++) {
			icon += arr[i];
			if (i < arr.length - 1) {
				icon += '-';
			}
		}
		return [type, icon];
	}

	const onBlockNotification = (blockId) => {
		if (!userState.user || !userState.user.$receive_notifications) return;
		notifications[blockId] = true;
		setNotifications(notifications);
		forceUpdate();
	}

	const renderBlock = (block) => {
		if (!block) return;

		const Block = importBlock(block.$path);

		const inHybridMode = localStorage.getItem('is_hybrid') == 'on';

		if (!inHybridMode || (inHybridMode && block.$visible_in_hybrid)) {
			return (
				<Col key={_uniqueId('col-')} sm={12} className="py-3" style={{ height: '100%' }}>
					<Suspense fallback={''}>
						<div id={"event-block-container-" + block.id} className="event-block-container" style={{ height: '100%' }}>
							<Block
								zone="zone02"
								notificationTopic={block.$notification_topic}
								blockID={block.$id}
								settings={block.settings}
								column_width={block.column_width}
								onNotification={onBlockNotification.bind(null, block.$id)}
								related={block.related}
							/>
						</div>
					</Suspense>
				</Col >
			);
		}
	}

	const style = () => {
		let s = `
        .custom-hamburger-inner {
            color: ` + props.color + ` !important;
            background: ` + props.color + ` !important;
        }
        .custom-hamburger-inner::after {
            background: ` + props.color + ` !important;
        }
        .custom-hamburger-inner::before {
            background: ` + props.color + ` !important;
        }
        .custom-badge {
            position: absolute !important;
            top: 0px !important;
            right: 0px !important;
        }
        `
		if (headerDrawerToggleRef.current) {
			s += `
                .modal {
                    padding-right: ` + sidebarWidth + `px !important;
                }
            `
		}
		return s;
	}

	return (
		<>
			<style>
				{style()}
			</style>

			{buttons.map((btn, index) => {
				const inHybridMode = localStorage.getItem('is_hybrid') == 'on';

				if (inHybridMode && !btn.$visible_in_hybrid) {
					return;
				}
				if (notifications[btn.$id] && activeBlockRef.current == index && headerDrawerToggleRef.current) {
					notifications[btn.$id] = false;
					setNotifications(notifications)
				}

				return (
					<Button
						key={"drawer-btn-" + index}
						onClick={(e) => {
							if (!headerDrawerToggleRef.current || index == activeBlockRef.current) {
								toggleDrawer();
							}

							setActiveBlock(index);
							activeBlockRef.current = index;

							notifications[btn.$id] = false;
							setNotifications(notifications);
							forceUpdate();
						}}
						id={"sidebarPopOver" + index}
						style={{ backgroundColor: ColorUtils.hexToRgba(props.color, 0.15), color: props.color }}
						className="sidebar-btn font-size-lg p-0 shadow-none border-0 text-center rounded position-relative mx-2 drawer-btn d-50 "
					>
						<div className="m-0" style={{ lineHeight: '0.65rem' }}>
							{notifications[btn.$id] && (
								<Badge color="success" className="badge-circle custom-badge" >
									New notifications
								</Badge>
							)}

							<FontAwesomeIcon icon={blockIcon(btn)} size="lg" />
						</div>
						<div className="m-0 p-0 pt-1" style={{ fontSize: '0.75rem', lineHeight: '0.65rem', fontWeight: 'bold' }}>{btn.$settings.blockTitle}</div>
					</Button>
				);
			})}
			<div className={clsx("app-drawer-content", { "header-drawer-open": headerDrawerToggleRef.current, })} style={{ transform: headerDrawerToggleRef.current ? "translateX(" + sidebarAnchor + ")" : "translateX(" + sidebarWidth + "px)" }}>
				<Button
					onClick={toggleDrawer}
					className="close-drawer-btn btn btn-sm"
					id="CloseDrawerTooltip"
				>
					<div
						className={clsx("navbar-toggler hamburger hamburger--elastic", {
							"is-active": headerDrawerToggleRef.current,
						})}
					>
						<span className="hamburger-box">
							<span className="custom-hamburger-inner hamburger-inner" style={{ color: props.color, backgroundColor: props.color + ' !important' }} />
						</span>

					</div>
				</Button>
				<UncontrolledTooltip target="CloseDrawerTooltip" placement="left">
					{t('close-drawer')}
				</UncontrolledTooltip>
				<div className="vh-100">
					<PerfectScrollbar className="mt-5">
						{blocks.map((block, blockIndex) => {
							if (!headerDrawerToggleRef.current && !forceHideRef.current) {
								return block;
							} else if (blockIndex == activeBlockRef.current) {
								return block;
							}
						})}
					</PerfectScrollbar>
				</div>
			</div >
			<div
				onClick={toggleDrawer}
				className={clsx("app-drawer-overlay", {
					"is-active": false,
				})}
			/>
		</>
	);
};

export default withSnackbar(withSocket(Zone02Sidebar))