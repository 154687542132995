import axios from 'axios';
import User from '../Models/User';

const AnalyticsService = {
	/**
	 * Send an authenticated post request.
	 * @param payload The payload for the put request.
	 * @throws error if the http response was not 200/201
	 */
	post: async function (
		payload: any,
		location: string,
		event: number,
		user: number,
		type: String
	): Promise<any> {
		try{
			const response = await axios.post(
				`${process.env.REACT_APP_ANALYTICS_URL}log`,
				{
					location: location,
					payload: payload,
					type: type,
					event_id: event,
					user_id: user,
				}
			);
	
			return response;
		} catch (e) {
			
		}
	},
};

export default AnalyticsService;
