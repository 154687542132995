import React, { FC, useEffect, useContext, Fragment } from 'react';
import { Context as EventContext } from '../../context/EventContext';
import { Context as UserContext } from '../../context/UserContext';
import AuthService from '../../Services/AuthService';
import AnalyticsService from '../../Services/AnalyticsService';
import { useNavigate } from 'react-router';
import NavigateUtils from '../../utils/NavigateUtils';

interface HeartbeatProps {
	heartbeatInterval: number;
}

const Heartbeat: FC<HeartbeatProps> = (props) => {
	const { userState, userDispatch } = useContext(UserContext);
	const { state, dispatch } = useContext(EventContext);
	const reactNavigate = useNavigate();


	useEffect(() => {
		// if (userState.user.$id == null) {
		// 	fetchUser();
		// }
	}, [userState.user]);

	const fetchUser = async () => {
		try {
			const user = await AuthService.getMe();
			userDispatch({
				type: 'SET_USER',
				user: user,
			});
		} catch (e) {
			if (state.event.base_url && !window.location.href.includes('/login')) {
				localStorage.removeItem("_myAraToken")
				localStorage.setItem('source_url_' + state.event.$slug, window.location.href);
				//navigate("/login")
			}
		}
	};

	const heartbeatFunction = async () => {

		let status = 'NOT_ACTIVE';
		if (!document.hidden) {
			status = 'ACTIVE';
		}

		AnalyticsService.post(
			{ status: status },
			window.location.pathname,
			state.event.$id,
			userState.user.$id,
			'heartbeat'
		);

		const inHybridMode = localStorage.getItem('is_hybrid') == 'on';
		if (state.event.hasService('hybrid')) {
			AnalyticsService.post(
				{ mode: inHybridMode ? 'hybrid' : 'normal' },
				window.location.pathname,
				state.event.$id, userState.user.$id, 'hybrid_heartbeat'
			);
		}
	};

	let beat = () => {
		heartbeatFunction();
	};

	useEffect(() => {
		const interval = setInterval(beat, props.heartbeatInterval);

		return () => {
			clearInterval(interval);
		};
	}, [state.event.$id]);

	return <Fragment />;
};

export default Heartbeat;
