import React from "react"

import clsx from "clsx"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	Row,
	Col,
	Badge,
	ListGroup,
	ListGroupItem,
	UncontrolledTooltip,
	Button,
} from "reactstrap"
import ChatBlock from "../../components/ChatBlock"
import { MessageCircle } from "react-feather"
import { connect } from "react-redux"

import { setHeaderDrawerToggle } from "../../reducers/ThemeOptions"


import {
	CircularProgressbarWithChildren,
	buildStyles,
} from "react-circular-progressbar"
import CountUp from "react-countup"
import Chart from "react-apexcharts"
import PerfectScrollbar from "react-perfect-scrollbar"

const HeaderDrawer = (props) => {
	const chartHeaderDrawerOptions = {
		chart: {
			toolbar: {
				show: false,
			},
			sparkline: {
				enabled: true,
			},
		},

		stroke: {
			color: "#1bc943",
			curve: "smooth",
			width: 4,
		},
		colors: ["#1bc943"],
		fill: {
			color: "1bc943",
			gradient: {
				shade: "light",
				type: "vertical",
				shadeIntensity: 0.7,
				inverseColors: true,
				opacityFrom: 1,
				opacityTo: 0,
				stops: [0, 100],
			},
		},
		xaxis: {
			crosshairs: {
				width: 1,
			},
		},
		yaxis: {
			min: 0,
		},
	}
	const chartHeaderDrawerData = [
		{
			name: "Monthly Analytics",
			data: [47, 38, 56, 24, 45, 54, 38, 56, 24, 65],
		},
	]

	const { headerDrawerToggle, setHeaderDrawerToggle } = props

	const toogleHeaderDrawer = () => {
		setHeaderDrawerToggle(!headerDrawerToggle)
	}

	return (
		<>
			<div className="app-drawer-wrapper">
				<Button
					onClick={toogleHeaderDrawer}
					color="neutral-success"
					className="bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative"
				>
					<span>
						<Badge color="success" className="badge-circle">
							New notifications
						</Badge>
						<MessageCircle />
					</span>
				</Button>
			</div>

			<div className="app-drawer-content">
				<Button
					onClick={toogleHeaderDrawer}
					className="close-drawer-btn btn btn-sm"
					id="CloseDrawerTooltip"
				>
					<div
						className={clsx("navbar-toggler hamburger hamburger--elastic", {
							"is-active": headerDrawerToggle,
						})}
					>
						<span className="hamburger-box">
							<span className="hamburger-inner" />
						</span>
					</div>
				</Button>
				<UncontrolledTooltip target="CloseDrawerTooltip" placement="left">
					Close drawer
				</UncontrolledTooltip>
				<div className="vh-100 shadow-overflow">
					<PerfectScrollbar>
						<ChatBlock></ChatBlock>
					</PerfectScrollbar>
				</div>
			</div>

			<div
				onClick={toogleHeaderDrawer}
				className={clsx("app-drawer-overlay", {
					"is-active": headerDrawerToggle,
				})}
			/>
		</>
	)
}

const mapStateToProps = (state) => ({
	headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
})

const mapDispatchToProps = (dispatch) => ({
	setHeaderDrawerToggle: (enable) => dispatch(setHeaderDrawerToggle(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDrawer)
