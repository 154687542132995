import React from 'react';
import {SocketContext, defaultQOS, defaultMQTTBroker} from './Socket'

export function withSocket(WrappedComponent: any) {
    return class extends React.Component {
        static contextType = SocketContext

        constructor(props) {
            super(props);
            this.subscribe = this.subscribe.bind(this);
            this.publish = this.publish.bind(this);
            this.connectToBroker = this.connectToBroker.bind(this);
            this.getClientId = this.getClientId.bind(this);
        }
        subscribe(topic,eventListener, forceUnique = false, qos = defaultQOS) {
            const { subscribeTo } = this.context;
            subscribeTo(topic, eventListener, forceUnique, qos);
        }

        publish(topic, payload, qos = defaultQOS) {
            const { publishTo } = this.context;
            publishTo(topic, payload, qos);
        }

        connectToBroker(host = defaultMQTTBroker) {
            const { mqttConnect } = this.context;
            mqttConnect(host);
        }

        getClientId() {
            const { getClientId } = this.context;
            return getClientId();
        }
        render() {
            return (
                <WrappedComponent
                subscribe={this.subscribe}
                publish={this.publish}
                connectToBroker={this.connectToBroker}
                getClientId={this.getClientId}
                {...this.props}
                />
            )
        }
    }
}