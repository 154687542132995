import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageService from "./Services/LanguageService";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  "EN": {
    "register": {
      "welcome.message": "Welcome to",
      "welcome.button": "Next",
      "welcome.sign_in": "Log in",
      "welcome.known_user": "Welcome back to the event!",
      "welcome.new_user": "Welcome to the event!"
    }
  },
  "NL": {
    "register": {
      "welcome.message": "Welkom op",
      "welcome.button": "Volgende",
      "welcome.sign_in": "Aanmelden",
      "welcome.known_user": "Welkom terug op het evenement",
      "welcome.new_user": "Welkom op het evenement"
    }
  }
}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "EN", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    saveMissing: true,
    react: { useSuspense: true },
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    missingKeyHandler: () => { }
  });

export default i18n;