export default class MetaTag {
	private id: number | undefined;
	private title: string | undefined;
	private description: string | undefined;
	private image: string | undefined;
	private favicon: string | undefined;
	private model_type: string | undefined;
	private model_id: number | undefined;

	constructor(
		id: number | undefined = undefined,
		title: string | undefined = undefined,
		description: string | undefined = undefined,
		image: string | undefined = undefined,
		favicon: string | undefined = undefined,
		model_type: string | undefined = undefined,
		model_id: number | undefined = undefined,
	) {
		this.id = id;
		this.title = title;
		this.description = description;
		this.image = image;
		this.favicon = favicon;
		this.model_id = model_id;
		this.model_type = model_type;
	}

	public static fromJSON(json: any): MetaTag {
		let tag = new MetaTag();
		Object.assign(tag, json);

		return tag;

	}

	public get $id(): number | undefined { return this.id }
	public get $title(): string | undefined { return this.title }
	public get $description(): string | undefined { return this.description }
	public get $image(): string | undefined { return this.image }
	public get $favicon(): string | undefined { return this.favicon }
	public get $model_type(): string | undefined { return this.model_type }
	public get $model_id(): number | undefined { return this.model_id }

	public set $id(value: number | undefined) { this.id = value; }
	public set $title(value: string | undefined) { this.title = value; }
	public set $description(value: string | undefined) { this.description = value; }
	public set $image(value: string | undefined) { this.image = value; }
	public set $favicon(value: string | undefined) { this.favicon = value; }
	public set $model_type(value: string | undefined) { this.model_type = value; }
	public set $model_id(value: number | undefined) { this.model_id = value; }
}