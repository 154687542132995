export default class LoadingUtils {

	/**
	 * Store the loading settings in local storage
	 * @param loadingSettings loading settings
	 */
	static storeLoadingSettings(loadingSettings: any, organisationSlug: string | undefined = undefined, eventSlug: string | undefined = undefined): void {
		if (loadingSettings && loadingSettings != '{}') {
			localStorage.setItem(this.getStorageKey(organisationSlug, eventSlug), loadingSettings);
		}
	}

	static getStorageKey(organisationSlug: string | undefined = undefined, eventSlug: string | undefined = undefined) {
		const loadingSettingsStorageKey = organisationSlug && eventSlug
			? organisationSlug + '_' + eventSlug + '_loading_settings'
			: window.location.hostname + '_loading_settings';
		return loadingSettingsStorageKey;
	}
}