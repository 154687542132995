import User from '../Models/User';

type Action = { type: 'SET_USER'; user: User };

type AsyncAction = {
	type: 'FETCH';
};

const UserReducer = (state, action) => {
	switch (action.type) {
		case 'SET_USER':
			return {
				...state,
				user: action.user,
			};
	}
};

export default UserReducer;
