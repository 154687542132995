import React, { FC, useState, useEffect, lazy, Suspense, useContext } from 'react';
import EventBlock from '../../Models/EventBlock';
import { Row, Col, NavItem, NavLink as NavLinkStrap, Nav, Card } from 'reactstrap';
import _uniqueId from 'lodash/uniqueId';
import LayoutService from '../../Services/LayoutService';
import { Context as EventContext } from '../../context/EventContext';
import * as FontIcon from 'react-icons/fa'
import clsx from "clsx"

// Dynamic block code
const importBlock = (c) =>
	lazy(() =>
		import(`../../blocks/${c}`).catch((error) => {
			console.error(`COULD NOT IMPORT Block ../../../blocks/${c}`);
			console.error(error);
		})
	);
function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue(value => value + 1); // update the state to force render
}

interface ZoneProps {
	tabs: Array<any>;
	eventBlocks: Array<EventBlock>;
	id: string
}

const MultipagerComponent: FC<ZoneProps> = (props: ZoneProps) => {
	const [dynBlocks, setDynBlocks] = useState([]);
	const { state, dispatch } = useContext(EventContext);
	const [layout, setLayout] = useState(undefined)
	const [activeTab, setActiveTab] = useState('');
	const forceUpdate = useForceUpdate();




	const getBlockById = (id) => {

		for (let b of dynBlocks) {
			if (b.id == id) return b;
		}
	}
	const renderBlock = (blockId, columnWidth) => {
		const block = getBlockById(blockId);

		if (!block) return;
		const Block = block.block;

		return (
			<div id={"event-block-container-" + block.id} key={"event-block-container-" + block.id} className="event-block-container py-3">
				<Block
					zone="zone01"
					notificationTopic={block.notification_topic}
					updateTopic={block.update_topic}
					blockID={block.id}
					settings={block.settings}
					column_width={columnWidth}
					related={block.related}
					inMultipager={true}
				/>
			</div>
		)
	}

	const getBlocksForTab = (tab) => {

		for (let t of props.tabs) {
			if (t.key == tab.key) {
				return tab;
			}
		}
	}

	const renderTabbedColumn = () => {
		const tabs = props.tabs;



		return (
			<>
				<Card className="p-md-4 p-2 mb-2 multipager-menu" id={props.id}>
					<div className="event-nav-tabs">
						<Nav className="nav-line">
							{tabs.map((tab, tabIndex) => {
								const inHybrid = localStorage.getItem('is_hybrid') == "on";
								if (!state.event.hasService('hybrid') || !inHybrid || (state.event.hasService('hybrid') && tab.showInHybrid)) {
									return (
										<NavItem
											id={"nav-item-" + tab.slug}
											key={tab.key}
											onClick={(e) => {
												setActiveTab(tab.key)
												forceUpdate();
											}}
										>
											<NavLinkStrap
												onClick={(e) => {
													let oldSlug = "";
													for (let t of tabs) {
														if (t.key == activeTab) {
															oldSlug = t.slug;
														}
													}
													if (oldSlug.length > 0) {
													}
													window.history.pushState("", tab.key, "#" + tab.slug);
												}}
												// href={'#' + tab.slug}
												className={clsx("p-0 mx-3 pb-2", {
													"active": activeTab == tab.key,
												})}>
												<span className="font-weight-bold text-uppercase font-size-sm">
													{tab.useImage ? (
														<img src={tab.icon} className="d-20 mr-2"/>
													) : (
														<>
															{tab.icon ? (<>
																{React.createElement(FontIcon[tab.icon], {
																	className: "mr-2"
																})}
															</>) : ''}
														</>
													)}
													{tab.title}
												</span>
											</NavLinkStrap>
										</NavItem>
									)
								}
							})}

						</Nav>
					</div>

				</Card>
				{tabs.map((tab, index) => {
					const inHybrid = localStorage.getItem('is_hybrid') == "on";
					if (!state.event.hasService('hybrid') || !inHybrid || (state.event.hasService('hybrid') && tab.showInHybrid)) {
						const blocks = getBlocksForTab(tab);
						return (
							<Suspense fallback={''} key={'layout-suspense-' + index}>
								{blocks && blocks.settings ? (
									<>
										{blocks.settings.map((row, rowIndex) => {

											return (
												<Row key={'layout-row-' + rowIndex} id={"layout-tab-" + tab.slug} className={clsx("layout-tab", { 'inactive-tab': tab.key != activeTab })} style={{ height: "100%" }}>
													{row.map((col, colIndex) => {

														if (col.column_type == 'default') {
															let containerClasses = "";
															for (let b of col.blocks) {
																containerClasses += "block-" + b.event_block_id + "-column ";
															}
															return (
																<Col sm={col.column_width} className={containerClasses} key={'layout-row-' + rowIndex + '-col-' + colIndex}>
																	{col.blocks.map((block, blockIndex) => {
																		if (tab.key == activeTab || block.enable_lazy_load) {
																			return renderBlock(block.event_block_id, col.column_width)
																		}
																	})}
																</Col>
															)
														}
													})}
												</Row>
											)
										})}
									</>
								) : ''}

							</Suspense>
						)
					}
				})}

			</>
		)
	}
	const handleActiveTab = () => {
		var url = window.location.href;
		var tabIds = url.split('#');
		tabIds.splice(0, 1)
		var at;

		for (let id of tabIds) {
			for (let tab of props.tabs) {
				if (tab.slug == id) {
					at = tab.key;
					break;
				}
			}
		}

		if (!at) {
			if (props.tabs.length > 0) {
				at = props.tabs[0].key;
			}
		}
		setActiveTab(at);
	}
	useEffect(() => {
		setDynBlocks(props.eventBlocks)
		handleActiveTab()
	}, [props.eventBlocks, props.id]);

	return (
		<>
			<style>
				{`
			.event-nav-tabs .nav.nav-line .nav-item .nav-link {
				border-bottom: 3px solid transparent;
			}
			.event-nav-tabs .nav.nav-line .nav-item .nav-link.active {
				color: ` + state.event.$color + `;
				border-bottom: 3px solid ` + state.event.$color + `;
			}
            .inactive-tab {
               display: none;
            }
			`}
			</style>



			{renderTabbedColumn()}



		</>
	);
};

export default MultipagerComponent;
