import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import HeaderNavigation from '../HeaderNavigation';
import { Context as EventContext } from '../../context/EventContext';
import { Card, Container } from 'reactstrap';

const Header = (props) => {
	const [width, setWidth] = useState(window.innerWidth);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
	const headerRef = useRef()

	const {
		headerShadow,
		headerBgTransparent,
		sidebarToggleMobile,
		setSidebarToggleMobile,
	} = props;

	const toggleSidebarMobile = () => {
		setSidebarToggleMobile(!sidebarToggleMobile);
	};
	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
		setIsMobile(window.innerWidth <= 992)
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
	}, [])

	const onSidebarToggle = (isOpen, sidebarWidth) => {
		if (isMobile && isOpen) {
			document.getElementsByTagName("body")[0].style.overflow = "hidden";

		} else {
			document.getElementsByTagName("body")[0].style.overflow = "visible";
		}


		if (headerRef.current) {
			if (isOpen && !isMobile) {
				headerRef.current.parentNode.style.marginRight = sidebarWidth + "px";
			} else {
				headerRef.current.parentNode.style.marginRight = "0px";
			}
		}
	}

	return (
		<>
			<Card className="ara-header" id="ara-header" style={{ borderRadius: '0px' }} innerRef={headerRef}>
				<Container fluid>
					<HeaderNavigation
						showBackButton={props.showBackButton}
						headerBgTransparent={headerBgTransparent}
						sidebarToggleMobile={sidebarToggleMobile}
						toggleSidebarMobile={toggleSidebarMobile}
						showTitle={props.showTitle}
						logo={props.logo}
						color={props.color}
						title={props.title}
						user={props.user}
						onSidebarToggle={onSidebarToggle}
						isMobile={isMobile}
					/>
				</Container>
					{props.children}
			</Card>
		</>
	);
};

const mapStateToProps = (state) => ({
	headerShadow: state.ThemeOptions.headerShadow,
	headerBgTransparent: state.ThemeOptions.headerBgTransparent,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
	setSidebarToggleMobile: (enable) =>
		dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
