import React from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Card, Container } from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';

import { connect } from 'react-redux';
import FooterZone from '../FooterZone';

const Footer = (props) => {
	const { footerShadow, footerBgTransparent } = props;
	return (
		<>
			<FooterZone/>
			{/* <Card className="footer-card" id="footer-card" style={{ borderRadius: '0px' }}>
				<Container fluid>
				</Container>
			</Card> */}
		</>
	);
};

export default Footer;
