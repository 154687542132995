import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	ListGroup,
	ListGroupItem,
	Nav,
	NavItem,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';
import { useTranslation } from "react-i18next";
import { NavLink as NavLinkStrap } from 'reactstrap';

import AnalyticsService from '../../Services/AnalyticsService';
import { Context as UserContext } from '../../context/UserContext';
import { Context as EventContext } from '../../context/EventContext';
import { Context as LanguageContext } from '../../context/LanguageContext';
import User from '../../Models/User';
import HeaderLanguageSelector from '../../layout-components/HeaderLanguageSelector';
import HeaderHybridSelector from '../../layout-components/HeaderHybridSelector';
import AuthService from '../../Services/AuthService';
import HeaderTimezoneSelector from '../HeaderTimezoneSelector';
import { useNavigate } from 'react-router';
import NavigateUtils from '../../utils/NavigateUtils';
import ColorUtils from '../../utils/ColorUtils';

const HeaderUserbox = (props) => {
	const [user, setUser] = useState(new User());
	const { userState, userDispatch } = useContext(UserContext);
	const { languageState, languageDispatch } = useContext(LanguageContext);
	const { state, dispatch } = useContext(EventContext);
	const [isLoading, setIsLoading] = useState(true);
	const { t, i18n } = useTranslation('header');
	const [url, setUrl] = useState(window.location.pathname);
	const reactNavigate = useNavigate();

	useEffect(() => {
		/*AuthService.getMe().then((me) => {
			setUser(me);
			setIsLoading(false);
		})*/
	}, []);

	const logout = () => {
		AnalyticsService.post(
			{ event: 'LOGGED OUT' },
			window.location.pathname,
			state.event.id,
			userState.user.id,
			'auth'
		);

		AuthService.logout();
		window.location.reload();
	};


	return (
		<>
			{!props.user ? (
				''
			) : (
				<>
					<style>
					{`
						.settings-cog-wheel:hover {
							color: `+ state.event.color + `;
						}
						.nav .nav-item .nav-link:hover {
							color: `+ state.event.color + `;
							background-color: `+ ColorUtils.hexToRgba(state.event.color, 0.15) + `;
						}
					`}
					</style>
					<UncontrolledDropdown className="position-relative ml-2">
						<DropdownToggle
							color="link"
							className="p-0 text-left d-flex btn-transition-none align-items-center settings-cog-wheel"
						>
							<div className="d-block pl-2">
								<FontAwesomeIcon
									icon={['fa', 'cog']}
									className="opacity-5"
								/>
							</div>
						</DropdownToggle>
						<DropdownMenu
							direction="right"
							className="dropdown-menu-lg overflow-hidden p-0"
						>
							<ListGroup flush className="text-left bg-transparent">
								<ListGroupItem className="rounded-top">
									<Nav
										pills
										className="nav-neutral-primary flex-column headr-user-box"
									>
										<NavItem>
											<NavLinkStrap
												onClick={() => {
													NavigateUtils.navigate(
														reactNavigate,
														state.event,
														'profile'
													);
												}}
											>
												<FontAwesomeIcon
													icon={['fa', 'user']}
													className="opacity-5 mr-2"
												/>{t('profile')}
											</NavLinkStrap>
										</NavItem>
										<NavItem>
											<NavLinkStrap onClick={(e) => logout()}>
												<FontAwesomeIcon
													icon={['fas', 'sign-out-alt']}
													className="opacity-5 mr-2"
												/>
												{t('logout')}
											</NavLinkStrap>
										</NavItem>

										{state.event.hasService('hybrid') && (
											<NavItem>
												<NavLinkStrap>
													<HeaderHybridSelector />
												</NavLinkStrap>
											</NavItem>
										)}

										<div className='divider'></div>
										{!props.disableTZSelector && (

											<NavItem>
												<NavLinkStrap>
													<HeaderTimezoneSelector />
												</NavLinkStrap>
											</NavItem>
										)}
										{(state.event?.$organisation && languageState.possibleLanguages.length > 1 && languageState.currentLanguage) && (
											<NavItem>
												<NavLinkStrap>
													<HeaderLanguageSelector
														eventSlug={state.event.$slug}
														organisationSlug={state.event.$organisation.slug}
														eventId={state.event.$id}
														relative={true}
														hideLabels={false}
														eventColor={state.event.$color}
													/>
												</NavLinkStrap>
											</NavItem>
										)}
									</Nav>
								</ListGroupItem>
							</ListGroup>
						</DropdownMenu>
					</UncontrolledDropdown>

				</>
			)}
		</>
	);
};

export default HeaderUserbox;
