import React, { createContext, useState, useEffect, FC, useContext, useRef } from 'react';
import _uniqueId from 'lodash/uniqueId';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withSocket } from '../Socket/withSocket';
import { Context as EventContext } from '../../context/EventContext';
import { Context as UserContext } from '../../context/UserContext';
import "../../HOC/Snackbar/style/snackbar.scss";
import { useTranslation } from "react-i18next";

import { VideoChatRoomService } from '../../Services/VideoChatRoomService';
import ringer from '../../assets/sounds/ringtone.mp3';
import { v4 as uuid } from 'uuid';
import {
	Alert,
	Row,
	Col,
	Container
} from 'reactstrap';
import SessionService from '../../Services/SessionService';
import ColorUtils from '../../utils/ColorUtils';
import { useNavigate } from 'react-router';
import NavigateUtils from '../../utils/NavigateUtils';


interface NotificationsProps {
	children: any;
}
function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue(value => value + 1); // update the state to force render
}

export const NotificationsContext = createContext({});

export const defaultPosition = 'top-right';
export const defaultDuration = 5000;
export const defaultInterval = 250;
export const positions = [
	'top-left',
	'top-center',
	'top-right',
	'bottom-left',
	'bottom-center',
	'bottom-right',
];

const Notifications: FC<NotificationsProps> = (props) => {
	const TIMEOUT_THRESHOLD = 2147483647;

	const { state, dispatch } = useContext(EventContext);
	const { userState, userDispatch } = useContext(UserContext);
	const forceUpdate = useForceUpdate();
	const { t } = useTranslation('headr/calendarBlock');

	// Current open state
	const [open, setOpen] = useState({})

	const [toastColor, setToastColor] = useState('rgba(0,0,0,0.4)')

	const [hasSubscribed, setHasSubscribed] = useState(false);

	const [notifications, setNotifications] = useState({})
	const reactNavigate = useNavigate();

	const timers = useRef({});

	const deleteNotification = (id) => {
		if (timers.current.hasOwnProperty(id)) {
			clearTimeout(timers.current[id]);
			delete timers.current[id];
		}
		if (notifications.hasOwnProperty(id)) {
			delete notifications[id];
			setNotifications(notifications);
		}
	}

	const calendarVideocallStartNotification = (notificationID, otherUser, room, ringtone) => {
		return (
			<div className="d-flex align-items-center align-content-center">
				<Container fluid>
					<Row>
						<Col md="12" className="pb-3 text-center d-flex justify-content-center align-items-center" style={{ color: 'white' }}>
							{otherUser.avatar ? (
								<div className="avatar-icon-wrapper avatar-icon-sm mr-3">
									<span style={{ position: 'absolute', right: '0px', bottom: '0px' }}><FontAwesomeIcon icon={['fas', 'video']} /></span>
									<div className="avatar-icon rounded-circle d-flex justify-content-center align-items-center"
										style={{ color: state.event.$color, width: '50px', height: '50px', boxShadow: 'none' }}
									>
										{otherUser.avatar ? (
											<img src={otherUser.avatar}></img>
										) : (
											<FontAwesomeIcon
												icon={["fas", "user"]}
												className="font-size-xl accordion-icon"
											/>
										)}
									</div>
								</div>
							) : (
								<span className="font-size-lg d-block btn-icon d-40 mr-3 text-center bg-white-50 rounded-sm p-3" style={{ color: 'white' }}>
									<FontAwesomeIcon icon={['fas', 'video']} />
								</span>
							)}
							<strong className="d-block">{otherUser.first_name} {otherUser.last_name} is calling you</strong>
						</Col>
						<Col
							md="6"
							className='text-center notification-btn'
							onClick={(e) => {
								setOpen(false);
								NavigateUtils.navigate(
									reactNavigate,
									state.event,
									"/videoChat/" + room.room_id
								);
							}}>
							<strong style={{ color: '#4ED964' }}>Accept</strong>
						</Col>
						<Col md="6" className='text-center notification-btn'
							onClick={async () => {
								await VideoChatRoomService.endCall(state.event.$id, room.room_id)
								open[notificationID] = false;
								setOpen(open);
								ringtone.pause();
								delete notifications[notificationID];
								setNotifications(notifications);
								forceUpdate();
							}}>
							<strong style={{ color: '#FF3A31' }}>Decline</strong>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}

	const calendarVideocallWillStartNotification = (otherUser, time) => {
		return (
			<div className="d-flex align-items-center align-content-center">
				<Container fluid>
					<Row>
						<Col md="12" className="pb-3 text-center d-flex justify-content-center align-items-center" style={{ color: 'white' }}>
							{otherUser.avatar ? (
								<div className="avatar-icon-wrapper avatar-icon-sm mr-3">
									<span style={{ position: 'absolute', right: '0px', bottom: '0px' }}><FontAwesomeIcon icon={['fas', 'video']} /></span>
									<div className="avatar-icon rounded-circle d-flex justify-content-center align-items-center"
										style={{ color: state.event.$color, width: '50px', height: '50px', boxShadow: 'none' }}
									>
										{otherUser.avatar ? (
											<img src={otherUser.avatar}></img>
										) : (
											<FontAwesomeIcon
												icon={["fas", "user"]}
												className="font-size-xl accordion-icon"
											/>
										)}

									</div>
								</div>
							) : (
								<span className="font-size-lg d-block btn-icon d-40 mr-3 text-center bg-white-50 rounded-sm p-3" style={{ color: 'white' }}>
									<FontAwesomeIcon icon={['fas', 'video']} />
								</span>
							)}
							<strong className="d-block">Meeting with {otherUser.first_name} {otherUser.last_name} will start in {time} minute {time > 1 ? <>s</> : ''}</strong>
						</Col>
					</Row>
				</Container>
			</div >
		)
	}

	const subscribeForVideoInvites = (userId) => {
		window.addEventListener('calendar-videocall-start', function (e) {
			const entry = (e as CustomEvent).detail.entry;
			const startTime = (e as CustomEvent).detail.startTime;

			if (startTime > TIMEOUT_THRESHOLD) {
				return;
			}

			let otherUser = undefined;

			for (let user of entry.invited) {
				if (user.id !== userId) {
					otherUser = user;
				}
			}
			const room = entry.model.video_chat_room;

			//Check if the user is already in the call:
			const currentUrl = window.location.pathname;
			if (currentUrl.includes("/videoChat/" + room.room_id)) {
				return;
			}

			const notificationID = 'calendar-videocall-start-' + entry.id;
			const ringtone = new Audio(ringer);
			ringtone.loop = true;

			notifications[notificationID] = calendarVideocallStartNotification(notificationID, otherUser, room, ringtone)
			setNotifications(notifications);

			if (timers.current.hasOwnProperty(notificationID)) {
				clearTimeout(timers.current[notificationID]);
			}
			timers.current[notificationID] = setTimeout(() => {
				open[notificationID] = true;
				setOpen(open);

				ringtone.play();

				forceUpdate();

				setTimeout(async () => {
					await VideoChatRoomService.endCall(state.event.$id, room.room_id)

					open[notificationID] = false;
					setOpen(open);

					delete notifications[notificationID];
					setNotifications(notifications);

					ringtone.pause();
					forceUpdate();
				}, 30000)
			}, startTime);
		});

		window.addEventListener('calendar-videocall-will-start', function (e) {
			const entry = (e as CustomEvent).detail.entry;
			const startTime = (e as CustomEvent).detail.startTime;
			const time = (e as CustomEvent).detail.time;

			if (startTime > TIMEOUT_THRESHOLD) {
				return;
			}

			let otherUser = undefined;
			for (let user of entry.invited) {
				if (user.id !== userId) {
					otherUser = user;
				}
			}
			const notificationID = 'calendar-videocall-will-start-' + entry.id;

			notifications[notificationID] = calendarVideocallWillStartNotification(otherUser, time)
			setNotifications(notifications)

			if (timers.current.hasOwnProperty(notificationID)) {
				clearTimeout(timers.current[notificationID]);
			}
			timers.current[notificationID] = setTimeout(() => {
				open[notificationID] = true;
				setOpen(open);

				forceUpdate();

				setTimeout(async () => {
					open[notificationID] = false;
					setOpen(open);

					delete notifications[notificationID];
					setNotifications(notifications);

					forceUpdate();
				}, 30000)
			}, startTime);
		});

		// When meeting is canceled
		props.subscribe("notifications/" + userState.user.$id + "/call/schedule", (msg) => {
			if (msg.message.startsWith('delete')) {
				const inviteId = msg.message.split(":")[1];

				// Cancel possible notifications
				const willStartId = 'calendar-videocall-will-start-' + inviteId;
				const startingId = 'calendar-videocall-start-' + inviteId;

				deleteNotification(willStartId);
				deleteNotification(startingId);
			}
		})

		setHasSubscribed(true);
	}

	const subscribeForCalls = (userId) => {
		props.subscribe("notifications/" + userId + "/call/incoming", (message) => {
			const info = JSON.parse(message.message.toString());

			//Check if the user is already in the call:
			const currentUrl = window.location.pathname;
			if (currentUrl.includes("/videoChat/" + info.room.room_id)) {
				return;
			}

			const ringtone = new Audio(ringer);
			ringtone.loop = true;
			ringtone.play();
			const notificationID = uuid();

			notifications[notificationID] = (
				<div className="d-flex align-items-center align-content-center">
					<Container fluid>
						<Row>
							<Col md="12" className="pb-3 text-center d-flex justify-content-center align-items-center" style={{ color: 'white' }}>
								{info.caller_avatar ? (
									<div className="avatar-icon-wrapper avatar-icon-sm mr-3">
										<span style={{ position: 'absolute', right: '0px', bottom: '0px' }}><FontAwesomeIcon icon={['fas', 'video']} /></span>
										<div className="avatar-icon rounded-circle d-flex justify-content-center align-items-center"
											style={{ color: state.event.$color, width: '50px', height: '50px', boxShadow: 'none' }}
										>
											{info.caller_avatar ? (
												<img src={info.caller_avatar}></img>
											) : (
												<FontAwesomeIcon
													icon={["fas", "user"]}
													className="font-size-xl accordion-icon"
												/>
											)}
										</div>
									</div>
								) : (
									<span className="font-size-lg d-block btn-icon d-40 mr-3 text-center bg-white-50 rounded-sm p-3" style={{ color: 'white' }}>
										<FontAwesomeIcon icon={['fas', 'video']} />
									</span>
								)}
								<strong className="d-block">{info['caller_name']} is calling you</strong>
							</Col>
							<Col
								md="6"
								className='text-center notification-btn'
								onClick={(e) => {
									setOpen(false);
									NavigateUtils.navigate(
										reactNavigate,
										state.event,
										"/videoChat/" + info.room.room_id
									)
								}}>
								<strong style={{ color: '#4ED964' }}>Accept</strong>
							</Col>
							<Col md="6" className='text-center notification-btn'
								onClick={async () => {
									await VideoChatRoomService.endCall(state.event.$id, info.room.room_id)
									open[notificationID] = false;
									setOpen(open);
									ringtone.pause();
									delete notifications[notificationID];
									setNotifications(notifications);
									forceUpdate();
								}}>
								<strong style={{ color: '#FF3A31' }}>Decline</strong>
							</Col>
						</Row>
					</Container>
				</div >
			)
			open[notificationID] = true;
			setOpen(open);
			setNotifications(notifications)
			forceUpdate();
			setTimeout(async () => {
				open[notificationID] = false;
				setOpen(open);
				delete notifications[notificationID];
				setNotifications(notifications);
				ringtone.pause();
				forceUpdate();
			}, 30000)
		});
		setHasSubscribed(true);

	}

	const writeNotificationCookie = (slug) => {
		let str = localStorage.getItem('received_notifications');
		if (!str) str = "[]";
		let cookie = JSON.parse(str);
		cookie.push(slug);
		str = JSON.stringify(cookie);
		localStorage.setItem('received_notifications', str);
	}

	const userHadNotification = (slug) => {
		let str = localStorage.getItem('received_notifications');
		if (!str) str = "[]";
		let cookie = JSON.parse(str);
		return cookie.includes(slug);
	}

	const calendarTimetableStartNotification = (notificationID, entry, url) => {
		return (
			<div className="d-flex align-items-center align-content-center">
				<Container fluid>
					<Row>
						<Col md="12" className="pb-3 text-center d-flex justify-content-center align-items-center" style={{ color: 'white' }}>
							<div className="avatar-icon-wrapper avatar-icon-sm mr-3">
								<span style={{ position: 'absolute', right: '0px', bottom: '0px' }}><FontAwesomeIcon icon={['fas', 'calendar']} /></span>
								<div className="avatar-icon rounded-circle d-flex justify-content-center align-items-center"
									style={{ color: state.event.$color, width: '50px', height: '50px', boxShadow: 'none' }}
								>
									<img src={entry.badge}></img>
								</div>
							</div>
							<strong className="d-block">{t("session-starting", { session_title: entry.title })}</strong>
						</Col>
						<Col
							md="6"
							className='text-center notification-btn'
							onClick={(e) => {
								setOpen(false);
								NavigateUtils.navigate(
									reactNavigate,
									state.event,
									url
								);
							}}>
							<strong style={{ color: '#4ED964' }}>{t('session-watch')}</strong>
						</Col>
						<Col md="6" className='text-center notification-btn'
							onClick={async () => {
								open[notificationID] = false;
								setOpen(open);
								forceUpdate();
							}}>
							<strong style={{ color: '#FF3A31' }}>{t('session-ignore')}</strong>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}

	const calendarTimetableWillStartNotification = (notificationID, entry, url, time) => {
		return (
			<div className="d-flex align-items-center align-content-center">
				<Container fluid>
					<Row>
						<Col md="12" className="pb-3 text-center d-flex justify-content-center align-items-center" style={{ color: 'white' }}>
							<div className="avatar-icon-wrapper avatar-icon-sm mr-3">
								<span style={{ position: 'absolute', right: '0px', bottom: '0px' }}><FontAwesomeIcon icon={['fas', 'calendar']} /></span>
								<div className="avatar-icon rounded-circle d-flex justify-content-center align-items-center"
									style={{ color: state.event.$color, width: '50px', height: '50px', boxShadow: 'none' }}
								>
									<img src={entry.badge}></img>
								</div>
							</div>
							<strong className="d-block">{t("session-will-start", { session_title: entry.title, time: time })}</strong>
						</Col>
						<Col
							md="6"
							className='text-center notification-btn'
							onClick={(e) => {
								setOpen(false);
								NavigateUtils.navigate(
									reactNavigate,
									state.event,
									url
								);
							}}>
							<strong style={{ color: '#4ED964' }}>{t('session-watch')}</strong>
						</Col>
						<Col md="6" className='text-center notification-btn'
							onClick={async () => {
								open[notificationID] = false;
								setOpen(open);
								forceUpdate();
							}}>
							<strong style={{ color: '#FF3A31' }}>{t('session-ignore')}</strong>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}

	/**
	 * Used for both subscriptions and registrations
	 */
	const subscribeForTimetableCalendar = async () => {
		window.addEventListener('calendar-timetable-start', async function (e) {
			const entry = (e as CustomEvent).detail.entry;
			const startTime = (e as CustomEvent).detail.startTime;
			const status = (e as CustomEvent).detail.status;

			if (startTime > TIMEOUT_THRESHOLD) {
				return;
			}

			if (userHadNotification('start-' + entry.key)) {
				return;
			}

			const notificationID = "calendar-timetable-start-" + entry.key;
			let url = "";
			if (entry.key.includes("session-")) {
				const sessionId = entry.key.replace('session-', '');
				const session = await SessionService.get(state.event.$id, sessionId);
				if (session.$rooms && session.$rooms.length > 0)
					url = "/room/" + session.$rooms[0].$slug;
			}

			notifications[notificationID] = calendarTimetableStartNotification(notificationID, entry, url)
			setNotifications(notifications)

			if (startTime < 0 || status == 'live') {
				// Already started, so show notification immediately
				open[notificationID] = true;
				setOpen(open);

				writeNotificationCookie('start-' + entry.key);

				forceUpdate();

				setTimeout(async () => {
					open[notificationID] = false;
					setOpen(open);

					delete notifications[notificationID];
					setNotifications(notifications);

					forceUpdate();
				}, 30000)
			} else {
				// Set timer for when the session starts
				if (timers.current.hasOwnProperty(notificationID)) {
					clearTimeout(timers.current[notificationID]);
				}
				timers.current[notificationID] = setTimeout(() => {
					open[notificationID] = true;
					setOpen(open);

					writeNotificationCookie('start-' + entry.key);

					forceUpdate();

					setTimeout(async () => {
						open[notificationID] = false;
						setOpen(open);

						delete notifications[notificationID];
						setNotifications(notifications);

						forceUpdate();
					}, 30000)
				}, startTime);
			}
		});

		window.addEventListener('calendar-timetable-will-start', async function (e) {
			const entry = (e as CustomEvent).detail.entry;
			const time = (e as CustomEvent).detail.time;
			const startTime = (e as CustomEvent).detail.startTime;

			if (startTime > TIMEOUT_THRESHOLD) {
				return;
			}

			if (userHadNotification('start-' + entry.key) || userHadNotification('pre-' + entry.key)) {
				return;
			}

			const notificationID = "calendar-timetable-will-start-" + entry.key;

			let url = "";
			if (entry.key.includes("session-")) {
				const sessionId = entry.key.replace('session-', '');
				const session = await SessionService.get(state.event.$id, sessionId);
				if (session.$rooms && session.$rooms.length > 0)
					url = "/room/" + session.$rooms[0].$slug;
			}

			notifications[notificationID] = calendarTimetableWillStartNotification(notificationID, entry, url, time)
			setNotifications(notifications);

			if (timers.current.hasOwnProperty(notificationID)) {
				clearTimeout(timers.current[notificationID]);
			}
			timers.current[notificationID] = setTimeout(() => {
				writeNotificationCookie('pre-' + entry.key);

				open[notificationID] = true;
				setOpen(open);

				forceUpdate();

				setTimeout(async () => {
					open[notificationID] = false;
					setOpen(open);

					delete notifications[notificationID];
					setNotifications(notifications);

					forceUpdate();
				}, 30000)
			}, startTime);
		});

		window.addEventListener('calendar-timetable-cancel', async function (e) {
			const entryKey = (e as CustomEvent).detail.entryKey;

			// Cancel possible notifications
			const willStartId = 'calendar-timetable-will-start-' + entryKey;
			const startingId = 'calendar-timetable-start-' + entryKey;

			deleteNotification(willStartId);
			deleteNotification(startingId);
		});
	}

	const subscribeForNotifications = (userId) => {
		subscribeForCalls(userId);
		subscribeForTimetableCalendar();
		subscribeForVideoInvites(userId)
		subscribeForAraNotifications();
	}

	const subscribeForAraNotifications = () => {
		window.addEventListener('show-ara-notification', (e) => {
			const notificationID = uuid();
			let customEvent = (e as CustomEvent);
			const title = customEvent.detail.title;
			const icon = customEvent.detail.icon;
			const msg = customEvent.detail.message;


			notifications[notificationID] = (
				<div className="d-flex align-items-center align-content-center">
					<Container fluid>
						<Row>
							<Col md="12" className="pb-3 text-center d-flex align-items-center" style={{ color: 'white' }}>
								{icon && (
									<FontAwesomeIcon icon={icon} color="white" className="mr-3" size="lg" />
								)}
								<strong className="d-block">{title}</strong>
							</Col>
						</Row>
						<Row>
							<Col md="12" style={{ color: 'white' }}>
								{msg}
							</Col>
						</Row>
					</Container>

				</div >
			)
			open[notificationID] = true;
			setOpen(open);
			setNotifications(notifications)
			forceUpdate();
			setTimeout(async () => {
				open[notificationID] = false;
				setOpen(open);
				delete notifications[notificationID];
				setNotifications(notifications);
				forceUpdate();
			}, 5000)
		});
	}

	useEffect(() => {
		if (state.event?.$color) {
			setToastColor(ColorUtils.hexToRgba(state.event.$color, 0.4))
			if (userState.user && userState.user.id && !hasSubscribed) {
				subscribeForNotifications(userState.user.id);
			}
		}

		return (() => {
			Object.keys(timers.current).forEach((notificationId) => clearTimeout(timers.current[notificationId]));
		})
	}, [state.event, userState.user]);

	return (
		<>
			<style>
				{`
                .notification-btn:hover {
                    cursor: pointer;
                }
                .notification-snackbar-container {
                    position: fixed;
                    top: 0px;
                    z-index: 9999;
                    right: 0px;
                }
				.notification-snackbar {
					background: ` + toastColor + `;
					border-radius: 16px;
					box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
					backdrop-filter: blur(16px);
					--webkit-backdrop-filter: blur(16px);
					border: none;
				}
            `}
			</style>
			<NotificationsContext.Provider value={{}}>
				{props.children}
				<div className='notification-snackbar-container '>
					{Object.keys(notifications).map((notificationID, idIndex) => {
						return (
							<CSSTransition
								key={'notification-' + notificationID}
								in={open[notificationID]}
								timeout={150}
								appear={false}
								mountOnEnter
								unmountOnExit
								classNames={'slide'}
							>
								<Alert className="snackbar notification-snackbar" >
									{notifications[notificationID]}
								</Alert>
							</CSSTransition>
						)
					})}
				</div>
			</NotificationsContext.Provider >
		</>
	)
}
export default withSocket(Notifications);