import axios from "axios";
import AuthService from "./AuthService";
import Event from "../Models/Event";
const PreviewService = {
    decryptPreviewInfo: async function(url, previewSlug) {
        const encodedUrl = btoa(url);
        const reqUrl = "preview/decrypt?url=" + encodedUrl + "&slug=" + previewSlug;
        const resp = await AuthService.get(reqUrl) 

        return resp;
    }
}
export default PreviewService;